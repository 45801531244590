import React, { useEffect, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getUpsellListData, postUpsell } from "../../store/upsells/action";
import { createUpsellSchema, upsellTypeOptions } from "../../models/upsells";
import {
  handleHtmlEditorChange,
  handleInputChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import FormTextField from "../../Components/Entity/FormTextField";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { getString } from "Components/Common/FormattedString";
import FormNumberField from "Components/Entity/FormNumberField";
import { FormSelect } from "Components/Entity/FormSelect";
import { installmentPositions } from "models/installmentPositions";
import FormHtmlEditor from "Components/Entity/FormHtmlEditor";

const AddUpsell = ({ visibility, toggle }) => {
  const dispatch = useDispatch();
  const { loading, formValidationErrors } = useSelector(
    (rootState) => rootState.Upsells,
  );

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      name: "",
      description: "",
      emailDescription: "",
      price: "",
      type: "",
      installmentPosition: "",
    },
    validationSchema: createUpsellSchema,
    onSubmit: (values) => {
      dispatch(
        postUpsell(values, () => {
          dispatch(getUpsellListData());
          toggle(false);
        }),
      );
    },
  });

  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors === null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("upsells_add_new_upsell")}
        formId={"add-upsell"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("add")}
      >
        <Form
          id="add-upsell"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              <FormTextField
                label={getString("name")}
                name={"name"}
                value={validation.values["name"]}
                onChange={(e) => handleInputChange(validation, e, "name")}
                error={validation.errors["name"]}
                touched={validation.touched["name"]}
              />
              <FormTextField
                label={getString("description_app")}
                name={"description"}
                value={validation.values["description"]}
                onChange={(e) =>
                  handleInputChange(validation, e, "description")
                }
                error={validation.errors["description"]}
                touched={validation.touched["description"]}
                tooltip={getString("description_app_tooltip")}
              />
              <FormHtmlEditor
                label={getString("emailDescription")}
                name={"emailDescription"}
                value={validation.values["emailDescription"]}
                onChange={(content) =>
                  handleHtmlEditorChange(
                    validation,
                    content,
                    "emailDescription",
                  )
                }
                error={validation.errors["emailDescription"]}
                tooltip="email_description_tooltip"
              />
              <FormNumberField
                label={getString("price")}
                name="price"
                value={validation.values.price}
                onChange={(e) => handleInputChange(validation, e, "price")}
                error={validation.errors.price}
                touched={validation.touched.price}
              />
              <FormSelect
                label={getString("type")}
                name={"type"}
                value={validation.values["type"]}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "type")
                }
                isMulti={false}
                options={upsellTypeOptions}
                error={validation.errors["type"]}
              />
              <FormSelect
                label={getString("installmentPosition")}
                name={"installmentPosition"}
                value={validation.values["installmentPosition"]}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    validation,
                    selectedOption,
                    "installmentPosition",
                  )
                }
                isMulti={false}
                options={installmentPositions}
                error={validation.errors["installmentPosition"]}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default AddUpsell;
