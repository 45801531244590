import React from "react";
import FormRangeField from "Components/Entity/FormRangeField";
import { getString } from "Components/Common/FormattedString";

const ParticipantAgeFilter = ({ onChange, filters }) => {
  const handleRangeChange = (e, name) => {
    onChange("participantAge", {
      ...filters.participantAge,
      [name]: e.target.value || undefined,
    });
  };

  return (
    <FormRangeField
      label={getString("participant_age")}
      fromName="min"
      toName="max"
      fromValue={filters.participantAge?.min || ""}
      toValue={filters.participantAge?.max || ""}
      onChange={handleRangeChange}
      min={0}
      max={120}
      data-testid="range-filter-participantAge"
    />
  );
};

export default ParticipantAgeFilter;
