import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

// Crypto Redux States
import {
  GET_HOTEL_LIST_DATA,
  POST_HOTEL,
  EDIT_HOTEL,
  ARCHIVE_HOTEL,
  RESTORE_HOTEL,
} from "./actionType";
import { hotelsApiSuccess, hotelsApiError } from "./action";

//Include Both Helper File with needed methods
import {
  getHotelList,
  postHotel,
  editHotel,
  archiveHotel,
  restoreHotel,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getHotelListData({ payload: data }) {
  try {
    const response = yield call(getHotelList, data);

    yield put(hotelsApiSuccess(GET_HOTEL_LIST_DATA, response));
  } catch (error) {
    yield put(hotelsApiError(GET_HOTEL_LIST_DATA, error));
  }
}

function* createHotel({ payload: hotel }) {
  try {
    const response = yield call(postHotel, hotel.data, hotel.onSuccess);
    yield put(hotelsApiSuccess(POST_HOTEL, response));
    toast_success("Venue added successfully");
  } catch (error) {
    yield put(hotelsApiError(POST_HOTEL, error));
    toast_error(error.data.message);
  }
}

function* editHotelEntry({ payload: hotel }) {
  try {
    const response = yield call(
      editHotel,
      hotel.id,
      hotel.data,
      hotel.onSuccess,
    );
    yield put(hotelsApiSuccess(EDIT_HOTEL, response));
    toast_success("Venue edited successfully");
  } catch (error) {
    yield put(hotelsApiError(EDIT_HOTEL, error));
    toast_error(error.data.message);
  }
}

function* archiveHotelEntry({ payload: hotel }) {
  try {
    const response = yield call(archiveHotel, hotel.id, hotel.onSuccess);
    yield put(hotelsApiSuccess(ARCHIVE_HOTEL, response));
    toast_success("Venue removed");
  } catch (error) {
    yield put(hotelsApiError(ARCHIVE_HOTEL, error));
    toast_error(error.data.message);
  }
}

function* restoreHotelEntry({ payload: hotel }) {
  try {
    const response = yield call(restoreHotel, hotel.id, hotel.onSuccess);
    yield put(hotelsApiSuccess(RESTORE_HOTEL, response));
    toast_success("Venue restored");
  } catch (error) {
    yield put(hotelsApiError(RESTORE_HOTEL, error));
    toast_error(error.data.message);
  }
}

export function* watchGetHotelListData() {
  yield takeEvery(GET_HOTEL_LIST_DATA, getHotelListData);
}

export function* watchPostHotel() {
  yield takeEvery(POST_HOTEL, createHotel);
}
export function* watchEditHotel() {
  yield takeEvery(EDIT_HOTEL, editHotelEntry);
}
export function* watchArchiveHotel() {
  yield takeEvery(ARCHIVE_HOTEL, archiveHotelEntry);
}
export function* watchRestoreHotel() {
  yield takeEvery(RESTORE_HOTEL, restoreHotelEntry);
}

function* hotelsSaga() {
  yield all([
    fork(watchGetHotelListData),
    fork(watchPostHotel),
    fork(watchEditHotel),
    fork(watchArchiveHotel),
    fork(watchRestoreHotel),
  ]);
}

export default hotelsSaga;
