import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Alert } from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, Navigate, useSearchParams } from "react-router-dom";

// actions
import { loginUser, resetLoginFlag } from "../../store/actions";
import { paths } from "../../config";

import logoLight from "../../assets/imagesn/logo-light.png";

import withRouter from "../../Components/Common/withRouter";
import { createSelector } from "reselect";
import { useTranslation } from "react-i18next";
import { FormattedString } from "Components/Common/FormattedString";

const Login = ({ router }) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state.Account;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      user: layout.user,
      errorMsg: layout.errorMsg,
      loading: layout.loading,
      error: layout.error,
    }),
  );

  const { errorMsg, error } = useSelector(selectLayoutProperties);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, error]);

  useEffect(() => {
    const email = searchParams.get("email");
    const token = searchParams.get("token");

    if (email && token) {
      dispatch(
        loginUser(
          {
            email: email,
            token: token,
          },
          router.navigate,
        ),
      );
    }
  }, [dispatch, router.navigate, searchParams]);

  document.title = t("login_page_title");
  const authUser = localStorage.getItem("authUser");

  if (authUser) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">
                        <FormattedString id="login_welcome_back" />
                      </h5>
                      <p className="text-muted">
                        <FormattedString id="login_sign_in_to_continue" />
                      </p>
                    </div>
                    {errorMsg && errorMsg ? (
                      <Alert color="danger"> {errorMsg} </Alert>
                    ) : null}
                    <div className="p-2 mt-4 mb-lg-5 text-center">
                      <div>
                        <Link
                          color="primary"
                          className="btn btn-label bg-primary-subtle"
                          to={paths.GOOGLE_LOGIN_URL}
                        >
                          <i className="ri-google-fill label-icon align-middle fs-16 me-2" />
                          <FormattedString id="login_google" />
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
