import React from "react";
import FormDateRange from "Components/Entity/FormDateRange";
import { getString } from "Components/Common/FormattedString";

const ProgramStartDateFilter = ({ onChange, filters }) => {
  const handleDateChange = (e, name) => {
    onChange("programStartDate", {
      ...filters.programStartDate,
      [name]: e.target.value || undefined,
    });
  };

  return (
    <FormDateRange
      label={getString("program_start_date")}
      startName="min"
      endName="max"
      startValue={filters.programStartDate?.min || ""}
      endValue={filters.programStartDate?.max || ""}
      onChange={handleDateChange}
      data-testid="date-filter-programStartDate"
    />
  );
};

export default ProgramStartDateFilter;
