import React from "react";
import FormSwitch from "Components/Entity/FormSwitch";
import { getString } from "Components/Common/FormattedString";

const ShowNotFinalizedFilter = ({ onChange, filters }) => {
  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    onChange("showNotFinalized", isChecked ? null : false);
  };

  return (
    <FormSwitch
      label={getString("show_not_finalized")}
      name="showNotFinalized"
      checked={filters.showNotFinalized !== false}
      onChange={handleSwitchChange}
      data-testid="switch-filter-showNotFinalized"
      noPadding
    />
  );
};

export default ShowNotFinalizedFilter;
