// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.json-preview {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}
.json-preview pre {
  margin: 0;
  font-family: monospace;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/DetailsBox/fields/JsonPreview/JsonPreview.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,SAAA;EACA,sBAAA;AAEJ","sourcesContent":[".json-preview {\n  background-color: #f5f5f5;\n  padding: 10px;\n  border-radius: 5px;\n  overflow-x: auto;\n  pre {\n    margin: 0;\n    font-family: monospace;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
