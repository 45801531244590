import { isObjectEmpty } from "helpers/utils";
import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  FETCH_PROFILE,
  SET_BUSINESS_UNIT,
  UPDATE_TABLE_PRESETS,
  UPDATE_TABLE_PRESETS_SUCCESS,
  UPDATE_TABLE_PRESETS_ERROR,
  SET_ACTIVE_PRESET,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  user: {},
  activeBusinessUnit:
    JSON.parse(localStorage.getItem("activeBusinessUnit")) || {},
  tablePresetsToSave: null,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = {
        ...state,
        success: action.payload.status,
        user: {
          ...action.payload.data,
          tablePresets: Array.isArray(action.payload.data.tablePresets)
            ? action.payload.data.tablePresets
            : [],
        },
        activeBusinessUnit: isObjectEmpty(state.activeBusinessUnit)
          ? action.payload.data.businessUnits[0]
          : state.activeBusinessUnit,
      };
      localStorage.setItem(
        "activeBusinessUnit",
        JSON.stringify(state.activeBusinessUnit),
      );
      break;
    case PROFILE_ERROR:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case SET_BUSINESS_UNIT:
      state = {
        ...state,
        activeBusinessUnit: action.payload,
      };
      localStorage.setItem(
        "activeBusinessUnit",
        JSON.stringify(action.payload),
      );
      break;
    case UPDATE_TABLE_PRESETS:
      state = {
        ...state,
        tablePresetsToSave: action.payload.tablePresets,
      };
      break;
    case UPDATE_TABLE_PRESETS_SUCCESS:
      state = {
        ...state,
        user: {
          ...state.user,
          tablePresets: state.tablePresetsToSave,
        },
        tablePresetsToSave: null,
      };
      break;
    case UPDATE_TABLE_PRESETS_ERROR:
      state = {
        ...state,
        error: action.payload,
        tablePresetsToSave: null,
      };
      break;
    case SET_ACTIVE_PRESET: {
      const { tableName, presetName } = action.payload;
      const updatedTablePresets = state.user.tablePresets.map((preset) =>
        preset.tableName === tableName
          ? {
              ...preset,
              activePreset: presetName,
            }
          : preset,
      );
      return {
        ...state,
        user: {
          ...state.user,
          tablePresets: updatedTablePresets,
        },
      };
    }
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
