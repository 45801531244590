import React, { useContext, useEffect, useState } from "react";
import "./ApplicationActivityTab.scss";
import { Card, CardBody, CardHeader, Col, Row, TabPane } from "reactstrap";
import { ApplicationDetailsContext } from "../../../ApplicationDetails";
import { FormattedString } from "Components/Common/FormattedString";
import { MailPreviewModal } from "./partials/MailPreviewModal";
import ActivityLogItem from "./ActivityLogItem";
import ApplicationDetailsRight from "./partials/ApplicationDetailsRight/ApplicationDetailsRight";

export const ApplicationActivityTab = () => {
  const { applicationLogs, refreshApplicationLogs } = useContext(
    ApplicationDetailsContext,
  );
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [expandedLogIndex, setExpandedLogIndex] = useState(null);
  const [comparisonLogIndex, setComparisonLogIndex] = useState(null);

  useEffect(() => {
    refreshApplicationLogs();
  }, []);

  const handleEmailPreview = (emailId) => {
    setSelectedEmailId(emailId);
    setIsModalVisible(true);
  };

  const toggleCollapse = (index) => {
    setExpandedLogIndex((prevIndex) => (prevIndex === index ? null : index));
    setComparisonLogIndex(null);
  };

  const toggleComparison = (index) => {
    setComparisonLogIndex((prevIndex) => (prevIndex === index ? null : index));
    setExpandedLogIndex(null);
  };

  const sortedLogs = applicationLogs.sort(
    (a, b) => new Date(a.occurredOn) - new Date(b.occurredOn),
  );

  return (
    <TabPane tabId="activity" className="application-activity-tab">
      <Row>
        <Col xxl={9}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 me-2">
                    <FormattedString id="recent_activity" />
                  </h4>
                </CardHeader>
                <CardBody>
                  <div className="application-activities">
                    {sortedLogs.length === 0 && (
                      <div className="application-activities__placeholder">
                        <FormattedString id="no_activities_available" />
                      </div>
                    )}
                    {sortedLogs.map((log, index) => {
                      const previousLog = sortedLogs
                        .slice(0, index)
                        .reverse()
                        .find((l) => l.eventName === log.eventName);

                      return (
                        <ActivityLogItem
                          key={index}
                          log={log}
                          index={index}
                          expandedLogIndex={expandedLogIndex}
                          comparisonLogIndex={comparisonLogIndex}
                          previousLog={previousLog}
                          toggleCollapse={toggleCollapse}
                          toggleComparison={toggleComparison}
                          handleEmailPreview={handleEmailPreview}
                        />
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xxl={3}>
          <ApplicationDetailsRight />
        </Col>
      </Row>
      {isModalVisible && (
        <MailPreviewModal
          emailId={selectedEmailId}
          setVisible={setIsModalVisible}
          visible={isModalVisible}
        />
      )}
    </TabPane>
  );
};
