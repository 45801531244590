import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { FormSelect } from "Components/Entity/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Form } from "reactstrap";
import { handleSelectChange } from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { getIpParticipantListData } from "store/ipParticipants/action";
import { toast_error, toast_success } from "helpers/toast_helper";
import { assignIpToProgramPacket } from "helpers/API/core-service/cs_backend_helper";
import { ipTypeOptions } from "models/ipParticipants";

export const AssignIpCanvas = ({ visible, setVisible, defaultPacket }) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);
  const [loading, setLoading] = useState(false);

  const programMeetingOptions = programData.programMeetingsNS.map((meeting) => {
    return {
      value: meeting.id,
      label: `${meeting.meetingPoint.city} ${meeting.startHour}`,
    };
  });

  const initialProgramMeetingId =
    programMeetingOptions.length === 1 ? programMeetingOptions[0].value : "";

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      internationalParticipantId: "",
      programPacketId: defaultPacket || "",
      type: "",
      programMeetingId: initialProgramMeetingId,
    },
    validationSchema: Yup.object({
      internationalParticipantId: Yup.string().required(
        getString("ip_required"),
      ),
      programPacketId: Yup.string().required(
        getString("program_packet_required"),
      ),
      type: Yup.string().required(getString("participant_type_required")),
      programMeetingId: Yup.string().required(
        getString("program_meeting_required"),
      ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      assignIpToProgramPacket(values.programPacketId, values)
        .then(() => {
          toast_success(getString("ip_assigned"));
          setVisible(false);
          refreshProgramData();
        })
        .catch((e) => toast_error(e.message))
        .finally(() => setLoading(false));
    },
  });

  const { ipParticipantsData } = useSelector(
    (rootState) => rootState.IpParticipants,
  );

  const ipsOptions = ipParticipantsData.map((i) => {
    return {
      value: i.id,
      label: `${i.firstName || ""} ${i.lastName || ""}, ${i.email}`,
    };
  });

  const programPacketOptions = programData.programPackets.map((packet) => {
    return { value: packet.id, label: packet.label };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIpParticipantListData());
  }, [dispatch]);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("ip_assign")}
      formId="assign-ip-form"
      validationRule={true}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card>
        <CardBody className="card-body">
          <Form
            id="assign-ip-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormSelect
              name="programPacketId"
              label={getString("program_packet_selected")}
              value={validation.values.programPacketId}
              onChange={(selectedOption) =>
                handleSelectChange(
                  validation,
                  selectedOption,
                  "programPacketId",
                )
              }
              defaultValue={programPacketOptions.find(
                (r) => r.value === validation.values.programPacketId,
              )}
              isMulti={false}
              options={programPacketOptions}
              error={validation.errors["programPacketId"]}
              isDisabled={!!defaultPacket}
            />
            <FormSelect
              name="participantType"
              label={getString("participant_type")}
              value={validation.values.type}
              onChange={(selectedOption) =>
                handleSelectChange(validation, selectedOption, "type")
              }
              isMulti={false}
              options={ipTypeOptions}
              error={validation.errors["type"]}
            />
            <FormSelect
              name="internationalParticipantId"
              label={getString("ip_selected")}
              value={validation.values.internationalParticipantId}
              onChange={(selectedOption) =>
                handleSelectChange(
                  validation,
                  selectedOption,
                  "internationalParticipantId",
                )
              }
              isMulti={false}
              options={ipsOptions}
              error={validation.errors["internationalParticipantId"]}
            />
            <FormSelect
              name="programMeetingId"
              label={getString("program_meeting")}
              value={validation.values.programMeetingId}
              onChange={(selectedOption) =>
                handleSelectChange(
                  validation,
                  selectedOption,
                  "programMeetingId",
                )
              }
              defaultValue={programMeetingOptions.find(
                (r) => r.value === validation.values.programMeetingId,
              )}
              isMulti={false}
              options={programMeetingOptions}
              error={validation.errors["programMeetingId"]}
            />
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
