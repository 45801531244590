import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedString, getString } from "Components/Common/FormattedString";
import {
  generateApplicationAbandonedReport,
  generateApplicationReport,
} from "helpers/API/core-service/cs_backend_helper";
import { getApplicationsListData } from "../../store/applications/action";
import CustomIdCell from "./cells/CustomIdCell/CustomIdCell";
import InstallmentsCell from "./cells/InstallmentsCell/InstallmentsCell";
import PackageCell from "./cells/PackageCell/PackageCell";
import GenderCell from "./cells/GenderCell/GenderCell";
import ParticipantNameCell from "./cells/ParticipantNameCell/ParticipantNameCell";
import ParticipantAgeCell from "./cells/ParticipantAgeCell/ParticipantAgeCell";
import InternalCommentCell from "./cells/InternalCommentCell/InternalCommentCell";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { format } from "date-fns";
import PresetTable from "Components/Common/PresetTable/PresetTable";
import { Button, Spinner } from "reactstrap";
import { downloadBlob } from "helpers/fileUtils";
import { splitNumbers } from "helpers/utils";

import ApplicationStatusFilter from "pages/Applications/filterComponents/ApplicationStatusFilter";
import ProgramFilter from "pages/Applications/filterComponents/ProgramFilter";
import PackageFilter from "pages/Applications/filterComponents/PackageFilter";
import ParticipantAgeFilter from "pages/Applications/filterComponents/ParticipantAgeFilter";
import ProgramStartDateFilter from "./filterComponents/ProgramStartDateFilter";
import ProgramEndDateFilter from "./filterComponents/ProgramEndDateFilter";
import CreatedAtFilter from "./filterComponents/CreatedAtFilter";
import ShowOnlyAbandonedFilter from "./filterComponents/ShowOnlyAbandonedFilter";
import ShowNotFinalizedFilter from "./filterComponents/ShowNotFinalizedFilter";

const Applications = () => {
  document.title = getString("applications_page_title");

  const dispatch = useDispatch();

  const [allLoading, setAllLoading] = useState(false);
  const [abandonedLoading, setAbandonedLoading] = useState(false);

  const { applicationsData, count, currentPage, totalPages, loading, error } =
    useSelector((state) => state.Applications);

  const handleGenerateAllReport = () => {
    setAllLoading(true);
    generateApplicationReport()
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        downloadBlob(blob, response.headers, "application_report.csv");
      })
      .catch((error) => {
        console.error("Error generating report:", error);
      })
      .finally(() => {
        setAllLoading(false);
      });
  };

  const handleGenerateAbandonedReport = () => {
    setAbandonedLoading(true);
    generateApplicationAbandonedReport()
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8;",
        });
        downloadBlob(
          blob,
          response.headers,
          "application_abandoned_report.csv",
        );
      })
      .catch((error) => {
        console.error("Error generating report:", error);
      })
      .finally(() => {
        setAbandonedLoading(false);
      });
  };

  const columns = [
    {
      id: "createdAt",
      Header: getString("created_date"),
      accessor: (d) => format(new Date(d.createdAt), "yyyy-MM-dd"),
      Cell: ({ value }) => <div style={{ color: "#6c757d" }}>{value}</div>,
    },
    {
      id: "id",
      Header: "ID",
      accessor: (row) => (
        <CustomIdCell
          customId={row.customId}
          to={`/pp-application/${row.id}`}
        />
      ),
      disableSortBy: true,
    },
    {
      id: "applicationStatus",
      Header: getString("status"),
      accessor: "applicationStatus",
      Cell: ({ value }) => (
        <div className="text-nowrap">
          <FormattedString id={value} />
        </div>
      ),
    },
    {
      id: "installments_short",
      Header: getString("installments_short"),
      accessor: (d) => (
        <InstallmentsCell
          installmentsPaid={d.installmentsPaid}
          installmentsCount={d.installmentsCount}
        />
      ),
      disableSortBy: true,
    },
    {
      id: "payments",
      Header: getString("payments"),
      accessor: (d) => `${d.paidAmount} / ${d.totalAmount}`,
      Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div>,
      disableSortBy: true,
    },
    {
      id: "customerId",
      Header: getString("client_name"),
      accessor: (d) => (
        <Link to={`/customer/${d.customerId}`}>
          {`${d.customerFirstName || ""} ${d.customerLastName || ""}`.trim() ||
            "-"}
        </Link>
      ),
    },
    {
      id: "client_phone",
      Header: getString("client_phone"),
      accessor: (d) =>
        `+${d.customerPhonePrefix} ${splitNumbers(d.customerPhoneNumber)}`,
      disableSortBy: true,
    },
    {
      id: "email",
      Header: getString("email"),
      accessor: "customerEmail",
      Cell: ({ value }) => <div style={{ color: "#6c757d" }}>{value}</div>,
      disableSortBy: true,
    },
    {
      id: "program",
      Header: getString("program"),
      accessor: "programName",
    },
    {
      id: "week",
      Header: getString("week"),
      accessor: "week",
      disableSortBy: true,
    },
    {
      id: "programStartDate",
      Header: getString("date"),
      accessor: (d) => (
        <div>
          <div>{format(new Date(d.dateStart), "yyyy-MM-dd")} - </div>
          <div>{format(new Date(d.dateEnd), "yyyy-MM-dd")}</div>
        </div>
      ),
    },
    {
      id: "package",
      Header: getString("package"),
      accessor: (d) => (
        <PackageCell
          packages={d.participants?.map((p) => p.programPacketName)}
        />
      ),
    },
    {
      id: "participantAge",
      Header: getString("age"),
      accessor: (d) => (
        <ParticipantAgeCell ages={d.participants?.map((p) => p.age)} />
      ),
    },
    {
      id: "gender",
      Header: getString("gender"),
      accessor: (d) => (
        <GenderCell genders={d.participants?.map((p) => p.gender)} />
      ),
      disableSortBy: true,
    },
    {
      id: "participant_name",
      Header: getString("participant_name"),
      accessor: (d) => (
        <ParticipantNameCell
          names={d.participants?.map((p) => p.firstName + " " + p.lastName)}
        />
      ),
      disableSortBy: true,
    },
    {
      id: "due_days",
      Header: getString("due_days"),
      accessor: "dueDays",
      Cell: ({ value }) => <div style={{ fontWeight: "bold" }}>{value}</div>,
      disableSortBy: true,
    },
    {
      id: "internal_comment",
      Header: getString("internal_comment"),
      accessor: (d) => (
        <InternalCommentCell
          internalComment={d.internalComment}
          entityId={d.id}
        />
      ),
      disableSortBy: true,
    },
    {
      id: "Actions",
      Header: () => (
        <div style={{ textAlign: "right" }}>
          <FormattedString id="actions" />
        </div>
      ),
      disableSortBy: true,
      accessor: (cellProps) => (
        <ActionButtons
          customShowDetailsLink={`/pp-application/${cellProps.id}`}
          itemId={cellProps.id}
        />
      ),
    },
  ];

  const filterMapping = [
    ProgramStartDateFilter,
    ProgramEndDateFilter,
    ApplicationStatusFilter,
    CreatedAtFilter,
    PackageFilter,
    ProgramFilter,
    ShowOnlyAbandonedFilter,
    ShowNotFinalizedFilter,
    ParticipantAgeFilter,
  ];

  return (
    <PresetTable
      filterMapping={filterMapping}
      title={getString("applications_list")}
      headerButtons={
        <>
          <Button
            color="light"
            className="generate-report-button text-primary"
            onClick={handleGenerateAllReport}
            disabled={allLoading}
          >
            <FormattedString id="generate_report_all" />
            {allLoading && (
              <Spinner size="sm" color="primary" className="ms-2" />
            )}
          </Button>

          <Button
            color="light"
            className="generate-report-button text-primary"
            onClick={handleGenerateAbandonedReport}
            disabled={abandonedLoading}
          >
            <FormattedString id="generate_report_abandoned" />
            {abandonedLoading && (
              <Spinner size="sm" color="primary" className="ms-2" />
            )}
          </Button>
        </>
      }
      getDataAction={(...props) => dispatch(getApplicationsListData(...props))}
      columns={columns}
      data={applicationsData}
      count={count}
      currentPage={currentPage}
      totalPages={totalPages}
      loading={loading}
      error={error}
      onRetry={() => dispatch(getApplicationsListData())}
      tableName="applications"
    />
  );
};

export default Applications;
