import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormMultiSelect } from "Components/Entity/FormMultiSelect";
import { getProgramListData } from "store/programs/action";
import { getString } from "Components/Common/FormattedString";

const ProgramFilter = ({ onChange, filters }) => {
  const dispatch = useDispatch();
  const { programsData } = useSelector((state) => state.Programs);
  const [programOptions, setProgramOptions] = useState([]);

  useEffect(() => {
    dispatch(getProgramListData());
  }, [dispatch]);

  useEffect(() => {
    const options = programsData.map((program) => ({
      value: program.id,
      label: `#${program.customId} ${program.name} ${new Date(program.startDate).toLocaleDateString()} - ${new Date(program.endDate).toLocaleDateString()}`,
    }));
    setProgramOptions(options);
  }, [programsData]);

  const handleChange = (selectedValues) => {
    onChange("program", selectedValues);
  };

  const programArray = Array.isArray(filters.program)
    ? filters.program
    : Object.values(filters.program || {});

  return (
    <FormMultiSelect
      name="program"
      isClearable
      label={getString("program")}
      values={programArray}
      setValues={handleChange}
      options={programOptions}
      data-testid="multi-select-filter-program"
    />
  );
};

export default ProgramFilter;
