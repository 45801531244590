import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

// Crypto Redux States
import {
  GET_INSTALLMENT_PLAN_LIST_DATA,
  POST_INSTALLMENT_PLAN,
  DELETE_INSTALLMENT_PLAN,
} from "./actionType";
import { installmentPlansApiSuccess, installmentPlansApiError } from "./action";

//Include Both Helper File with needed methods
import {
  getInstallmentPlanList,
  postInstallmentPlan,
  deleteInstallmentPlan,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getInstallmentPlanListData({ payload: data }) {
  try {
    const response = yield call(getInstallmentPlanList, data);

    yield put(
      installmentPlansApiSuccess(GET_INSTALLMENT_PLAN_LIST_DATA, response),
    );
  } catch (error) {
    yield put(installmentPlansApiError(GET_INSTALLMENT_PLAN_LIST_DATA, error));
  }
}

function* createInstallmentPlan({ payload: installmentPlan }) {
  try {
    const response = yield call(
      postInstallmentPlan,
      installmentPlan.data,
      installmentPlan.onSuccess,
    );
    yield put(installmentPlansApiSuccess(POST_INSTALLMENT_PLAN, response));
    toast_success("Installment Plan added successfully");
  } catch (error) {
    yield put(installmentPlansApiError(POST_INSTALLMENT_PLAN, error));
    toast_error(error.data.message);
  }
}

function* deleteInstallmentPlanEntry({ payload: installmentPlan }) {
  try {
    const response = yield call(
      deleteInstallmentPlan,
      installmentPlan.id,
      installmentPlan.onSuccess,
    );
    yield put(installmentPlansApiSuccess(DELETE_INSTALLMENT_PLAN, response));
    toast_success("Installment Plan removed");
  } catch (error) {
    yield put(installmentPlansApiError(DELETE_INSTALLMENT_PLAN, error));
    toast_error(error.data.message);
  }
}

export function* watchGetInstallmentPlanListData() {
  yield takeEvery(GET_INSTALLMENT_PLAN_LIST_DATA, getInstallmentPlanListData);
}

export function* watchPostInstallmentPlan() {
  yield takeEvery(POST_INSTALLMENT_PLAN, createInstallmentPlan);
}

export function* watchDeleteInstallmentPlan() {
  yield takeEvery(DELETE_INSTALLMENT_PLAN, deleteInstallmentPlanEntry);
}

function* installmentPlansSaga() {
  yield all([
    fork(watchGetInstallmentPlanListData),
    fork(watchPostInstallmentPlan),
    fork(watchDeleteInstallmentPlan),
  ]);
}

export default installmentPlansSaga;
