import React, { useState } from "react";
import { usePresetTableContext } from "../PresetTableContext";
import { ClearButton } from "Components/Common/ClearButton/ClearButton";

const PresetTableFilters = ({ filters, setFilters }) => {
  const { filterMapping } = usePresetTableContext();
  const [rerenderTrigger, setRerenderTrigger] = useState(false);

  const handleFilterChange = (filterName, value) => {
    const isObject = (val) => val && typeof val === "object";

    let newValue = value;

    if (isObject(value)) {
      newValue = Object.entries(value).reduce((acc, [key, val]) => {
        if (val !== null && val !== undefined && val !== "") {
          acc[key] = val;
        }
        return acc;
      }, {});

      if (Object.keys(newValue).length === 0) {
        newValue = null;
      }
    }

    if (Array.isArray(newValue) && newValue.length === 0) {
      newValue = null;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: newValue,
    }));
  };

  const handleClearFilters = () => {
    setFilters({});
    setRerenderTrigger((prev) => !prev);
  };

  const filtersLength = Object.keys(filters).length;

  return (
    <div key={rerenderTrigger} data-testid="preset-table-filters">
      {filterMapping.map((FilterComponent, index) => (
        <div key={index} className="mb-3">
          <FilterComponent filters={filters} onChange={handleFilterChange} />
        </div>
      ))}
      <div className="d-flex justify-content-end mt-3">
        <ClearButton
          onClick={handleClearFilters}
          className="d-flex align-items-center"
          clearStringId="clear_filters"
          disabled={filtersLength === 0}
          data-testid="clear-filters-button"
        />
      </div>
    </div>
  );
};

export default PresetTableFilters;
