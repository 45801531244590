import React from "react";
import { Col, Container, Row } from "reactstrap";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";
import { BillingDataList } from "./BillingDataList";

const BillingData = () => {
  document.title = getString("billing_data_page_title");
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList title={getString("billing_data_list")}>
                  <BillingDataList />
                </EntityCardList>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BillingData;
