import { PayerTypes } from "models/payerTypes";
import validationSchema from "./validationSchema";
import { getString } from "Components/Common/FormattedString";

export const validate = (values) => {
  const errors = {};
  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (validationErrors) {
    validationErrors.inner.forEach((error) => {
      errors[error.path] = error.message;
    });
  }

  const { payerType, firstName, lastName, companyName, taxNumber } =
    values.payerDetails;
  if (payerType === PayerTypes.PERSON) {
    if (!firstName) {
      errors["payerDetails.firstName"] = getString("first_name_required");
    }
    if (!lastName) {
      errors["payerDetails.lastName"] = getString("last_name_required");
    }
  } else if (payerType === "company") {
    if (!companyName) {
      errors["payerDetails.companyName"] = getString("company_name_required");
    }
    if (!taxNumber) {
      errors["payerDetails.taxNumber"] = getString("tax_number_required");
    }
  }

  return errors;
};
