import React from "react";
import { afStatusOptions } from "models/applicationStatuses";
import { getString } from "Components/Common/FormattedString";
import { FormMultiSelect } from "Components/Entity/FormMultiSelect";

const ApplicationStatusFilter = ({ onChange, filters }) => {
  const handleChange = (selectedValues) => {
    onChange("applicationStatus", selectedValues);
  };

  const applicationStatusArray = Array.isArray(filters.applicationStatus)
    ? filters.applicationStatus
    : Object.values(filters.applicationStatus || {});

  return (
    <FormMultiSelect
      name="applicationStatus"
      isClearable
      label={getString("application_status")}
      values={applicationStatusArray}
      setValues={handleChange}
      options={afStatusOptions}
      data-testid="multi-select-filter-applicationStatus"
    />
  );
};

export default ApplicationStatusFilter;
