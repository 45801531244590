import React, { useEffect, useMemo, useRef } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBillingDataList } from "../../store/billingData/action";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { Badge } from "reactstrap";
import "./BillingDataList.scss";
import { getString } from "Components/Common/FormattedString";

const BillingDataList = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);

  const {
    billingData,
    count,
    currentPage,
    totalPages,
    pageSize,
    loading,
    error,
  } = useSelector((rootState) => rootState.BillingData);

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
    dispatch(getBillingDataList(pageNumber, globalFilter));
  }, [searchParams]);

  const columns = useMemo(
    () => [
      {
        Header: getString("custom_id"),
        accessor: "customId",
        Cell: ({ value }) => <span className="text-muted">{value}</span>,
      },
      {
        Header: getString("type"),
        accessor: "type",
        Cell: ({ value }) => (
          <Badge color={value === "person" ? "success" : "info"}>{value}</Badge>
        ),
      },
      {
        Header: getString("name"),
        accessor: "name",
        Cell: ({ row }) => (
          <Link to={`/customer/${row.original.customerId}`}>
            <strong>{row.original.name}</strong>
          </Link>
        ),
      },
      {
        Header: getString("address"),
        accessor: "address",
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: getString("zip_code"),
        accessor: "zipCode",
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: getString("city"),
        accessor: "city",
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        Header: getString("tax_number"),
        accessor: "taxNumber",
        Cell: ({ value }) => <span>{value || "-"}</span>,
      },
    ],
    [],
  );

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <div className="billing-data-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        loading={loading}
        globalFilter={globalFilter}
        setGlobalFilter={customSetGlobalFilter}
        onPageChange={handlePageChange}
        data={billingData || []}
        isBordered={false}
        isGlobalFilter={true}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("billing_data_search_for")}
        error={error}
        onRetry={() => dispatch(getBillingDataList(pageNumber, globalFilter))}
        preventLoading={false}
      />
    </div>
  );
};

export { BillingDataList };
