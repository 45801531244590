// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-switch.no-padding {
  padding: 0;
}
.form-switch .switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}
.form-switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.form-switch .switch input:checked + .slider {
  background-color: #2196f3;
}
.form-switch .switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.form-switch .switch input:checked + .slider:before {
  transform: translateX(14px);
}
.form-switch .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}
.form-switch .switch .slider.round {
  border-radius: 34px;
}
.form-switch .switch .slider.round:before {
  border-radius: 50%;
}
.form-switch .switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}`, "",{"version":3,"sources":["webpack://./src/Components/Entity/FormSwitch.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,UAAA;AACJ;AAEE;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AAAJ;AAEI;EACE,UAAA;EACA,QAAA;EACA,SAAA;AAAN;AAEM;EACE,yBAAA;AAAR;AAGM;EACE,2BAAA;AADR;AAIM;EACE,2BAAA;AAFR;AAMI;EACE,kBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AAJN;AAMM;EACE,mBAAA;AAJR;AAMQ;EACE,kBAAA;AAJV;AAQM;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,uBAAA;EACA,gBAAA;AANR","sourcesContent":[".form-switch {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &.no-padding {\n    padding: 0;\n  }\n\n  .switch {\n    position: relative;\n    display: inline-block;\n    width: 34px;\n    height: 20px;\n\n    input {\n      opacity: 0;\n      width: 0;\n      height: 0;\n\n      &:checked + .slider {\n        background-color: #2196f3;\n      }\n\n      &:focus + .slider {\n        box-shadow: 0 0 1px #2196f3;\n      }\n\n      &:checked + .slider:before {\n        transform: translateX(14px);\n      }\n    }\n\n    .slider {\n      position: absolute;\n      cursor: pointer;\n      top: 0;\n      left: 0;\n      right: 0;\n      bottom: 0;\n      background-color: #ccc;\n      transition: 0.4s;\n      border-radius: 34px;\n\n      &.round {\n        border-radius: 34px;\n\n        &:before {\n          border-radius: 50%;\n        }\n      }\n\n      &:before {\n        position: absolute;\n        content: \"\";\n        height: 14px;\n        width: 14px;\n        left: 3px;\n        bottom: 3px;\n        background-color: white;\n        transition: 0.4s;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
