import React, { useState } from "react";
import { Col, Row, CardBody } from "reactstrap";
import { useAsyncDebounce } from "react-table";
import { usePresetTableContext } from "../PresetTableContext";

const GlobalFilter = ({ SearchPlaceholder }) => {
  const { globalFilter, customSetGlobalFilter } = usePresetTableContext();
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    customSetGlobalFilter(value || undefined);
  }, 400);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <CardBody>
      <form onSubmit={handleSubmit}>
        <Row className="g-3">
          <Col>
            <div className="search-box me-2 mb-2 d-inline-block col-12">
              <input
                onChange={(e) => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control search"
                placeholder={SearchPlaceholder}
                value={value || ""}
                data-testid="preset-table-global-filter-input"
              />
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </Col>
        </Row>
      </form>
    </CardBody>
  );
};

export default GlobalFilter;
