import classNames from "classnames";
import { FormattedString } from "../FormattedString";
import "./MinimalisticButton.scss";

export const MinimalisticButton = ({
  buttonStringId = "button_label",
  disabled = false,
  onClick,
  color = "default",
  icon,
  "data-testid": dataTestId,
}) => {
  return (
    <button
      className={classNames([
        "minimalistic-button",
        `minimalistic-button--${color}`,
        disabled && "minimalistic-button--disabled",
      ])}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {icon && <i className={classNames(icon, "minimalistic-button__icon")} />}
      <FormattedString id={buttonStringId} />
    </button>
  );
};
