import { call, put, all, fork, takeLatest } from "redux-saga/effects";

import { GET_BILLING_DATA_LIST } from "./actionType";
import { billingDataApiSuccess, billingDataApiError } from "./action";

import { getBillingDataList } from "../../helpers/API/core-service/cs_backend_helper";

function* getBillingDataListData({ payload: data }) {
  try {
    const { page, searchTerm, pageSize } = data;
    const response = yield call(getBillingDataList, page, searchTerm, pageSize);

    yield put(billingDataApiSuccess(GET_BILLING_DATA_LIST, response));
  } catch (error) {
    yield put(billingDataApiError(GET_BILLING_DATA_LIST, error));
  }
}

export function* watchGetBillingDataListData() {
  yield takeLatest(GET_BILLING_DATA_LIST, getBillingDataListData);
}

function* billingDataSaga() {
  yield all([fork(watchGetBillingDataListData)]);
}

export default billingDataSaga;
