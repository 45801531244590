import React from "react";
import {
  Button,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Spinner,
} from "reactstrap";

import "./OffcanvasRight.scss";
import { FormattedString } from "Components/Common/FormattedString";

const OffcanvasRight = ({
  isOpen,
  toggle,
  title,
  formId,
  validationRule,
  loading = false,
  buttonLabel,
  children,
  onSubmit,
}) => {
  return (
    <React.Fragment>
      <Offcanvas
        isOpen={isOpen}
        toggle={toggle}
        direction="end"
        className="border-bottom offcanvas-right"
      >
        <OffcanvasHeader toggle={() => toggle(false)} id="offcanvasRightLabel">
          {title}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">{children}</OffcanvasBody>
        <div className="offcanvas-footer offcanvas-right__footer">
          <Button color="light" onClick={() => toggle(false)}>
            <FormattedString id="back" />
          </Button>
          <Button
            form={formId}
            color="primary"
            disabled={!validationRule || loading}
            type="submit"
            onClick={onSubmit}
            data-testid="submit-button"
          >
            {loading ? <Spinner size="sm" className="me-2" /> : null}{" "}
            {buttonLabel}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
};

export default OffcanvasRight;
