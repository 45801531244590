export const downloadBlob = (blob, headers, defaultFilename) => {
  const url = window.URL.createObjectURL(blob);

  const contentDisposition = headers.get("content-disposition");
  let filename = defaultFilename;
  if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches !== null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
