import React from "react";
import "./FormSelect.scss";
import { FormattedString } from "Components/Common/FormattedString";
import classNames from "classnames";
import Select from "react-select";
import { FormFeedback, Label } from "reactstrap";

export const FormSelectGrouped = ({
  label,
  name,
  value,
  options,
  setValue,
  error,
  "data-testid": dataTestId,
  ...rest
}) => {
  return (
    <div className="mb-3" data-testid={dataTestId || `select-${name}`}>
      {(label && (
        <Label htmlFor={name} className="form-label">
          {label}
        </Label>
      )) ||
        (name && (
          <Label htmlFor={name} className="form-label">
            {label || <FormattedString id={name} />}
          </Label>
        ))}
      <Select
        name={name}
        options={options}
        value={options
          .flatMap((group) => group.options)
          .find((v) => v.value === value)}
        className={classNames(["form-select", error && "is-invalid"])}
        isMulti={false}
        classNamePrefix="form-select"
        onChange={(selectedOption) => setValue(selectedOption.value)}
        {...rest}
        padding="none"
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};
