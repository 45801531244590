import React, { useEffect } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getProgramPacketTemplateListData,
  editProgramPacketTemplate,
} from "../../store/programPacketTemplates/action";
import {
  createProgramPacketTemplateSchema,
  targetAudienceOptions,
} from "models/programPacketTemplates";
import {
  handleInputChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import FormTextField from "Components/Entity/FormTextField";
import { getString } from "Components/Common/FormattedString";
import FormNumberField from "Components/Entity/FormNumberField";
import { FormSelect } from "Components/Entity/FormSelect";
import FormTextArea from "Components/Entity/FormTextArea";
import DescriptionList from "Components/Entity/DescriptionList";

const EditProgramPacketTemplate = ({
  programPacketTemplate,
  toggle,
  visibility,
}) => {
  const dispatch = useDispatch();

  const { loading, formValidationErrors } = useSelector(
    (state) => state.ProgramPacketTemplates,
  );

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      name: programPacketTemplate?.name || "",
      label: programPacketTemplate?.label || "",
      ppMinAge: programPacketTemplate?.ppMinAge || "",
      ppMaxAge: programPacketTemplate?.ppMaxAge || "",
      ipMinAge: programPacketTemplate?.ipMinAge || "",
      ipMaxAge: programPacketTemplate?.ipMaxAge || "",
      targetAudience: programPacketTemplate?.targetAudience || "",
      descriptionItems: programPacketTemplate?.descriptionItems || [],
      detailedDescription: programPacketTemplate?.detailedDescription || "",
    },
    validationSchema: createProgramPacketTemplateSchema,
    onSubmit: (values) => {
      dispatch(
        editProgramPacketTemplate(programPacketTemplate.id, values, () => {
          dispatch(getProgramPacketTemplateListData());
          toggle(null, false);
        }),
      );
    },
  });

  useEffect(() => {
    if (formValidationErrors === null) {
      return;
    }
    setValidationErrors(validation, formValidationErrors);
  }, [formValidationErrors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={"Edit " + programPacketTemplate?.name}
        formId={"edit-programPacketTemplate"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("save")}
      >
        <Form
          id="edit-programPacketTemplate"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              <FormTextField
                label={getString("name")}
                name={"name"}
                value={validation.values["name"]}
                onChange={(e) => handleInputChange(validation, e, "name")}
                error={validation.errors["name"]}
                touched={validation.touched["name"]}
              />
              <FormTextField
                label={getString("label")}
                name={"label"}
                value={validation.values["label"]}
                onChange={(e) => handleInputChange(validation, e, "label")}
                error={validation.errors["label"]}
                touched={validation.touched["label"]}
              />
              <FormNumberField
                label={getString("ppMinAge")}
                name="ppMinAge"
                value={validation.values.ppMinAge}
                onChange={(e) => handleInputChange(validation, e, "ppMinAge")}
                error={validation.errors.ppMinAge}
                touched={validation.touched.ppMinAge}
              />
              <FormNumberField
                label={getString("ppMaxAge")}
                name="ppMaxAge"
                value={validation.values.ppMaxAge}
                onChange={(e) => handleInputChange(validation, e, "ppMaxAge")}
                error={validation.errors.ppMaxAge}
                touched={validation.touched.ppMaxAge}
              />
              <FormNumberField
                label={getString("ipMinAge")}
                name="ipMinAge"
                value={validation.values.ipMinAge}
                onChange={(e) => handleInputChange(validation, e, "ipMinAge")}
                error={validation.errors.ipMinAge}
                touched={validation.touched.ipMinAge}
              />
              <FormNumberField
                label={getString("ipMaxAge")}
                name="ipMaxAge"
                value={validation.values.ipMaxAge}
                onChange={(e) => handleInputChange(validation, e, "ipMaxAge")}
                error={validation.errors.ipMaxAge}
                touched={validation.touched.ipMaxAge}
              />
              <FormSelect
                label={getString("targetAudience")}
                name={"targetAudience"}
                value={validation.values["targetAudience"]}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    validation,
                    selectedOption,
                    "targetAudience",
                  )
                }
                isMulti={false}
                options={targetAudienceOptions}
                defaultValue={targetAudienceOptions.find(
                  (r) => r.value === validation.values.targetAudience,
                )}
                error={validation.errors["targetAudience"]}
              />
              <DescriptionList
                label={getString("descriptionItems")}
                name={"descriptionItems"}
                value={validation.values["descriptionItems"]}
                onChange={(items) =>
                  handleInputChange(
                    validation,
                    { target: { name: "descriptionItems", value: items } },
                    "descriptionItems",
                  )
                }
                error={validation.errors["descriptionItems"]}
                touched={validation.touched["descriptionItems"]}
              />
              <FormTextArea
                label={getString("detailedDescription")}
                name={"detailedDescription"}
                value={validation.values["detailedDescription"]}
                onChange={(e) =>
                  handleInputChange(validation, e, "detailedDescription")
                }
                error={validation.errors["detailedDescription"]}
                touched={validation.touched["detailedDescription"]}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default EditProgramPacketTemplate;
