import "./LinkButton.scss";
import classNames from "classnames";

export const LinkButton = ({
  children,
  onClick,
  className,
  disabled,
  "data-testid": dataTestId,
}) => {
  return (
    <span
      className={classNames("link-button", className, {
        "link-button--disabled": disabled,
      })}
      onClick={!disabled ? onClick : undefined}
      data-testid={dataTestId}
    >
      {children}
    </span>
  );
};
