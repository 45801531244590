import React from "react";
import { FormSelect } from "Components/Entity/FormSelect";
import { Spinner } from "reactstrap";
import { getString } from "Components/Common/FormattedString";

const AssignedProgram = ({
  assignedProgram,
  index,
  validation,
  programOptions,
  programPackets,
  programMeetings,
  fetchingPackets,
  removeProgram,
  fetchProgramPackets,
  errors,
  success,
  disabled,
}) => {
  const backgroundColor = success
    ? "lightgreen"
    : errors
      ? "lightcoral"
      : "#f8f9fa";

  return (
    <div
      key={assignedProgram.id}
      className="program-assignment"
      style={{ backgroundColor }}
      data-testid={`assigned-program-${index}`}
    >
      <div className="program-header">
        <div>{`Program Assignment #${index + 1}`}</div>
        <i
          className="ri-close-line remove-icon"
          onClick={() => removeProgram(index)}
          data-testid={`remove-program-button-${index}`}
        />
      </div>
      <FormSelect
        name={`assignedPrograms[${index}].programId`}
        data-testid={`program-select-${index}`}
        label={getString("program")}
        defaultValue={programOptions.find(
          (o) => o.value === assignedProgram.programId,
        )}
        value={assignedProgram.programId}
        onChange={(selectedOption) => {
          validation.setFieldValue(
            `assignedPrograms[${index}].programId`,
            selectedOption.value,
          );
          fetchProgramPackets(selectedOption.value, index);
        }}
        isMulti={false}
        options={programOptions}
        error={validation.errors.assignedPrograms?.[index]?.programId}
        isDisabled={success || disabled}
      />
      {fetchingPackets[index] ? (
        <div className="text-center my-4">
          <Spinner color="primary" className="mt-4" />
        </div>
      ) : (
        <>
          <FormSelect
            name={`assignedPrograms[${index}].programPacketId`}
            data-testid={`program-packet-select-${index}`}
            label={getString("program_packet")}
            value={assignedProgram.programPacketId}
            defaultValue={(programPackets[index] || []).find(
              (o) => o.value === assignedProgram.programPacketId,
            )}
            onChange={(selectedOption) =>
              validation.setFieldValue(
                `assignedPrograms[${index}].programPacketId`,
                selectedOption.value,
              )
            }
            options={programPackets[index] || []}
            error={validation.errors.assignedPrograms?.[index]?.programPacketId}
            isDisabled={success || disabled || !assignedProgram.programId}
          />
          <FormSelect
            name={`assignedPrograms[${index}].programMeetingId`}
            data-testid={`program-meeting-select-${index}`}
            label={getString("program_meeting")}
            value={assignedProgram.programMeetingId}
            defaultValue={(programMeetings[index] || []).find(
              (o) => o.value === assignedProgram.programMeetingId,
            )}
            onChange={(selectedOption) =>
              validation.setFieldValue(
                `assignedPrograms[${index}].programMeetingId`,
                selectedOption.value,
              )
            }
            options={programMeetings[index] || []}
            error={
              validation.errors.assignedPrograms?.[index]?.programMeetingId
            }
            isDisabled={success || disabled || !assignedProgram.programId}
          />
        </>
      )}
      {errors && <div className="error-message">{errors}</div>}
      <div style={{ height: "10px" }}></div>
    </div>
  );
};

export default AssignedProgram;
