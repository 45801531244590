export const FETCH_PROFILE = "FETCH_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const SET_BUSINESS_UNIT = "SET_BUSINESS_UNIT";

export const UPDATE_TABLE_PRESETS = "UPDATE_TABLE_PRESETS";
export const UPDATE_TABLE_PRESETS_SUCCESS = "UPDATE_TABLE_PRESETS_SUCCESS";
export const UPDATE_TABLE_PRESETS_ERROR = "UPDATE_TABLE_PRESETS_ERROR";
export const SET_ACTIVE_PRESET = "SET_ACTIVE_PRESET";
export const SET_ACTIVE_PRESET_SUCCESS = "SET_ACTIVE_PRESET_SUCCESS";
export const SET_ACTIVE_PRESET_ERROR = "SET_ACTIVE_PRESET_ERROR";
