import React, { useContext, useState } from "react";
import "./UpsellsOverview.scss";
import { CardBody, Progress, Row } from "reactstrap";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { DeletePpUpsellModal } from "../../modals/DeletePpUpsellModal";
import { ProgramDetailsContext } from "pages/Programs/ProgramDetails/ProgramDetails";
import { getString } from "Components/Common/FormattedString";
import { calculatePercentage } from "helpers/utils";

export const UpsellsOverview = ({ upsells }) => {
  const { canUpdateProgram } = useContext(ProgramDetailsContext);
  const [ppUpsellToDelete, setPpUpsellToDelete] = useState(null);
  const [deletePpUpsellModalVisible, setDeletePpUpsellModalVisible] =
    useState(false);

  const filterUpsells = (type) =>
    upsells.filter((upsell) => upsell.type === type);
  const calculateGroupTotal = (group) =>
    group.reduce((acc, upsell) => acc + (upsell.count || 0), 0);

  const upsellTypes = [...new Set(upsells.map((upsell) => upsell.type))];
  const prioritizedUpsellTypes = [
    ...upsellTypes.filter(
      (type) => type !== "touristic" && type !== "educational",
    ),
    ...upsellTypes.filter(
      (type) => type === "touristic" || type === "educational",
    ),
  ];

  const colors = [
    "primary",
    "warning",
    "success",
    "danger",
    "secondary",
    "dark",
    "light",
  ];
  const getColor = (index) => colors[index % colors.length];

  const renderOverview = (title, total, group, isSecondary) => (
    <div className="single-overview">
      <Row className="align-items-center justify-content-between mb-3">
        <p className="text-muted fw-semibold text-truncate mb-0 fs-5">
          {title}
        </p>
      </Row>
      {isSecondary ? (
        <div className="mt-3">
          {group.map((upsell) => (
            <div
              key={upsell.id}
              className="upsell-row mb-2 d-flex justify-content-between"
            >
              <div
                className="text-truncate text-muted fs-14 mb-0 d-flex flex-grow-1"
                style={{ flex: 1 }}
              >
                <AngloTooltip
                  text={upsell.description}
                  enabled={upsell.description}
                >
                  {upsell.name}
                </AngloTooltip>
              </div>
              <div
                className="flex-grow-1 d-flex justify-content-center"
                style={{ flex: 1 }}
              >
                <Progress
                  value={calculatePercentage(
                    upsell.confirmedCount,
                    upsell.confirmedSum,
                  )}
                  color="danger"
                  className="animated-progress custom-progress progress-label"
                >
                  <div className="label">
                    {calculatePercentage(
                      upsell.confirmedCount,
                      upsell.confirmedSum,
                    )}
                    %
                  </div>
                </Progress>
              </div>
              <div
                className="flex-grow-1 d-flex justify-content-end"
                style={{ flex: 1 }}
              >
                <p className="mb-0 me-2">
                  <span>{upsell.confirmedCount}</span>{" "}
                  <span style={{ color: "grey" }}>
                    (
                    {calculatePercentage(
                      upsell.confirmedCount,
                      upsell.confirmedSum,
                    )}
                    %)
                  </span>
                </p>
                {canUpdateProgram && (
                  <AngloTooltip stringId="delete">
                    <button
                      id={`deleteButton-${upsell.id}`}
                      type="button"
                      className="delete-btn"
                      onClick={() => {
                        setPpUpsellToDelete(upsell.id);
                        setDeletePpUpsellModalVisible(true);
                      }}
                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  </AngloTooltip>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Progress multi className="progress-lg rounded-pill">
            {group.map((upsell, index) => (
              <Progress
                key={upsell.id}
                bar
                color={getColor(index)}
                value={calculatePercentage(upsell.count, total)}
              />
            ))}
          </Progress>
          <div className="mt-3">
            {group.map((upsell, index) => (
              <div key={upsell.id} className="upsell-row mb-2">
                <div className="text-truncate text-muted fs-14 mb-0 d-flex">
                  <i
                    className={`mdi mdi-circle align-middle text-${getColor(index)} me-2`}
                  ></i>
                  <AngloTooltip
                    text={upsell.description}
                    enabled={upsell.description}
                  >
                    {upsell.name}
                  </AngloTooltip>
                </div>
                <div className="flex-shrink-0 d-flex">
                  <p className="mb-0 me-2">
                    <span>{upsell.count}</span>{" "}
                    <span style={{ color: "grey" }}>
                      ({calculatePercentage(upsell.count, total)}%)
                    </span>
                  </p>
                  {canUpdateProgram && (
                    <AngloTooltip stringId="delete">
                      <button
                        id={`deleteButton-${upsell.id}`}
                        type="button"
                        className="delete-btn"
                        onClick={() => {
                          setPpUpsellToDelete(upsell.id);
                          setDeletePpUpsellModalVisible(true);
                        }}
                      >
                        <i className="ri-delete-bin-line"></i>
                      </button>
                    </AngloTooltip>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <CardBody className="upsells-overview">
      {prioritizedUpsellTypes.map((type, index, array) => {
        const group = filterUpsells(type);
        const total = calculateGroupTotal(group);
        const isSecondary = type === "educational" || type === "touristic";
        return (
          <React.Fragment key={index}>
            {renderOverview(getString(type), total, group, isSecondary)}
            {index < array.length - 1 && <hr className="my-4" />}
          </React.Fragment>
        );
      })}
      {deletePpUpsellModalVisible && (
        <DeletePpUpsellModal
          itemId={ppUpsellToDelete}
          visible={deletePpUpsellModalVisible}
          setVisible={setDeletePpUpsellModalVisible}
        />
      )}
    </CardBody>
  );
};
