import React from "react";
import FormDateRange from "Components/Entity/FormDateRange";
import { getString } from "Components/Common/FormattedString";

const ProgramEndDateFilter = ({ onChange, filters }) => {
  const handleDateChange = (e, name) => {
    onChange("programEndDate", {
      ...filters.programEndDate,
      [name]: e.target.value || undefined,
    });
  };

  return (
    <FormDateRange
      label={getString("program_end_date")}
      startName="min"
      endName="max"
      startValue={filters.programEndDate?.min || ""}
      endValue={filters.programEndDate?.max || ""}
      onChange={handleDateChange}
      data-testid="date-filter-programEndDate"
    />
  );
};

export default ProgramEndDateFilter;
