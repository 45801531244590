import "./ProgramProgress.scss";
import { Progress } from "reactstrap";
import { DetailRow } from "../DetailRow/DetailRow";
import { isObjectEmpty } from "helpers/utils";
import { getString } from "../FormattedString";
import { Tile } from "../Tile/Tile";

export const ProgramProgress = ({ programData }) => {
  const { programPackets, programPrices, installmentPlans } = programData;

  const areCoordinatorsFilled = () => {
    if (isObjectEmpty(programPackets)) {
      return false;
    }
    return programPackets.every((packet) => packet.coordinators.length > 0);
  };

  const matchPrice = (programPacket, installmentPlan) => {
    return programPrices.find(
      (price) =>
        price.programPacketId === programPacket.id &&
        price.installmentPlanId === installmentPlan.id,
    );
  };

  const areProgramPacketsFilled = () => {
    if (isObjectEmpty(programPackets) || isObjectEmpty(installmentPlans)) {
      return false;
    }

    let filled = true;

    programPackets.forEach((programPacket) => {
      installmentPlans.forEach((installmentPlan) => {
        !matchPrice(programPacket, installmentPlan) && (filled = false);
      });
    });
    return filled;
  };

  const areUpsellsFilled = () => {
    return (
      programData.participantUpsells.length > 0 &&
      programData.internationalParticipantUpsells.length > 0
    );
  };

  const progressUnits = [
    "hotel",
    "healthAndSafetyCoordinators",
    "coordinators",
    "programMeetingsNS",
    "programMeetingsPP",
    "programPackets",
    "upsells",
  ];

  const isUnitFilled = (unit) => {
    switch (unit) {
      case "coordinators":
        return areCoordinatorsFilled();

      case "programPackets":
        return areProgramPacketsFilled();

      case "upsells":
        return areUpsellsFilled();

      default:
        if (!isObjectEmpty(programData[unit])) {
          return true;
        }
        break;
    }
    return false;
  };

  const calculateProgress = () => {
    let absoluteProgress = 0;
    const unitProgress = 100 / progressUnits.length;

    progressUnits.forEach((unit) => {
      isUnitFilled(unit) && (absoluteProgress += unitProgress);
    });
    return Math.round(absoluteProgress);
  };

  const progressBarValue = calculateProgress() || 0;

  const renderTick = (unit) => {
    return isUnitFilled(unit) ? (
      <i className="ri-check-fill"></i>
    ) : (
      <i className="ri-close-fill"></i>
    );
  };

  return (
    progressBarValue !== 100 && (
      <Tile title={getString("program_completion_progress")}>
        <div className="program-progress">
          {progressUnits.map((unit) => {
            return (
              <DetailRow
                key={unit}
                name={getString(unit)}
                value={renderTick(unit)}
                hidden={isUnitFilled(unit)}
              />
            );
          })}
          <Progress
            value={progressBarValue}
            color="danger"
            className="animated-progress custom-progress progress-label"
          >
            <div className="label">{progressBarValue}%</div>
          </Progress>
        </div>
      </Tile>
    )
  );
};
