import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Card, CardBody, Form } from "reactstrap";
import { handleSelectChange } from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { toast_error, toast_success } from "helpers/toast_helper";
import { updateIpApplicationStatus } from "helpers/API/core-service/cs_backend_helper";
import { ipGroupedStatusOptions } from "models/ipParticipants";
import { FormSelectGrouped } from "Components/Entity/FormSelectGrouped";

export const ChangeIpApplicationStatusCanvas = ({
  visible,
  setVisible,
  application,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      status: application?.applicationStatus || "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required(getString("status_required")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      updateIpApplicationStatus(
        application.programPacketId,
        application.internationalParticipantId,
        values.status,
      )
        .then(() => {
          toast_success(getString("status_updated"));
          setVisible(false);
          refreshData();
        })
        .catch((e) => toast_error(e.message))
        .finally(() => setLoading(false));
    },
  });

  useEffect(() => {
    if (application) {
      validation.setFieldValue("status", application.applicationStatus);
    }
  }, [application]);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("change_ip_status")}
      formId="change-ip-status-form"
      validationRule={true}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card>
        <CardBody className="card-body">
          <Form
            id="change-ip-status-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormSelectGrouped
              name="status"
              label={getString("status")}
              value={validation.values.status}
              onChange={(selectedOption) =>
                handleSelectChange(validation, selectedOption, "status")
              }
              isMulti={false}
              options={ipGroupedStatusOptions}
              error={validation.errors["status"]}
            />
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
