import React, { useContext, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import {
  handleInputChange,
  setValidationErrors,
} from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString, getStringSync } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { CustomerDetailsContext } from "pages/Customers/CustomerDetails/CustomerDetails";
import FormTextField from "Components/Entity/FormTextField";
import * as Yup from "yup";
import { toast_success } from "helpers/toast_helper";
import { editCustomerPayerData } from "helpers/API/account-service/as_backend_helper";

const EditCustomerBillingCanvas = ({ visible, setVisible }) => {
  const { customerData, refreshCustomerData } = useContext(
    CustomerDetailsContext,
  );
  const [loading, setLoading] = useState(false);

  const sortedPayerDetails = customerData.payerDetails.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  );
  const newestPayerDetail = sortedPayerDetails[0] || {};

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      firstName: newestPayerDetail.firstName || "",
      lastName: newestPayerDetail.lastName || "",
      address: newestPayerDetail.address || "",
      zipCode: newestPayerDetail.zipCode || "",
      city: newestPayerDetail.city || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(getStringSync("enter_first_name")),
      lastName: Yup.string().required(getStringSync("enter_last_name")),
      address: Yup.string().required(getStringSync("enter_address")),
      zipCode: Yup.string().required(getStringSync("enter_zip_code")),
      city: Yup.string().required(getStringSync("enter_city")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      editCustomerPayerData(customerData.id, newestPayerDetail.id, values)
        .then(() => {
          toast_success(getString("customer_billing_details_updated"));
          refreshCustomerData();
          setVisible(false);
        })
        .catch((e) => {
          console.error(e.message);
          setValidationErrors(validation, e.data.fields);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(!visible)}
      title={getString("edit_customer_billing_details")}
      formId={"edit-customer-billing-details"}
      validationRule={validation.isValid}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Form
        id="edit-customer-billing-details"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Card>
          <CardBody className="card-body">
            <FormTextField
              label={getString("first_name")}
              name="firstName"
              value={validation.values.firstName}
              onChange={(e) => handleInputChange(validation, e, "firstName")}
              error={validation.errors.firstName}
            />
            <FormTextField
              label={getString("last_name")}
              name="lastName"
              value={validation.values.lastName}
              onChange={(e) => handleInputChange(validation, e, "lastName")}
              error={validation.errors.lastName}
            />
            <FormTextField
              label={getString("address")}
              name="address"
              value={validation.values.address}
              onChange={(e) => handleInputChange(validation, e, "address")}
              error={validation.errors.address}
            />
            <FormTextField
              label={getString("zip_code")}
              name="zipCode"
              value={validation.values.zipCode}
              onChange={(e) => handleInputChange(validation, e, "zipCode")}
              error={validation.errors.zipCode}
            />
            <FormTextField
              label={getString("city")}
              name="city"
              value={validation.values.city}
              onChange={(e) => handleInputChange(validation, e, "city")}
              error={validation.errors.city}
            />
          </CardBody>
        </Card>
      </Form>
    </OffcanvasRight>
  );
};

export default EditCustomerBillingCanvas;
