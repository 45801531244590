import React from "react";
import { Modal } from "reactstrap";
import { getString } from "Components/Common/FormattedString";
import ConfirmDeleteBody from "Components/Entity/ConfirmDeleteBody";

export const DeleteTablePresetModal = ({
  presetName,
  visibility,
  toggle,
  handleDelete,
}) => (
  <Modal
    isOpen={visibility}
    toggle={toggle}
    backdrop="static"
    id="staticBackdrop"
    centered
  >
    <ConfirmDeleteBody
      entityName={`${getString("table_preset_sm")} '${presetName}'`}
      toggle={toggle}
      deleteAction={handleDelete}
    />
  </Modal>
);

export default DeleteTablePresetModal;
