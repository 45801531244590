import React from "react";
import "./FormSelect.scss";
import { FormattedString } from "Components/Common/FormattedString";
import classNames from "classnames";
import Select from "react-select";
import { FormFeedback, Label } from "reactstrap";

export const FormMultiSelect = ({
  label,
  name,
  values,
  setValues,
  options,
  error,
  "data-testid": dataTestId,
  ...rest
}) => {
  const selectedValues = Array.isArray(values) ? values : [];

  return (
    <div className="mb-3" data-testid={dataTestId || `select-${name}`}>
      {(label && (
        <Label htmlFor={name} className="form-label">
          {label}
        </Label>
      )) ||
        (name && (
          <Label htmlFor={name} className="form-label">
            {label || <FormattedString id={name} />}
          </Label>
        ))}
      <Select
        name={name}
        options={options}
        value={options.filter((v) => selectedValues.includes(v.value))}
        className={classNames(["form-select", error && "is-invalid"])}
        isMulti={true}
        classNamePrefix="form-select"
        onChange={(selectedOptions) =>
          setValues(selectedOptions.map((option) => option.value))
        }
        {...rest}
        padding="none"
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};
