import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  FETCH_PROFILE,
  UPDATE_TABLE_PRESETS,
  UPDATE_TABLE_PRESETS_SUCCESS,
  UPDATE_TABLE_PRESETS_ERROR,
  SET_ACTIVE_PRESET,
  SET_ACTIVE_PRESET_SUCCESS,
  SET_ACTIVE_PRESET_ERROR,
} from "./actionTypes";

export const fetchUserProfile = (user) => {
  return {
    type: FETCH_PROFILE,
    payload: { user },
  };
};

export const profileSuccess = (actionType, data) => {
  return {
    type: PROFILE_SUCCESS,
    payload: { actionType, data },
  };
};

export const profileError = (actionType, data) => {
  return {
    type: PROFILE_ERROR,
    payload: { actionType, data },
  };
};

export const updateTablePresets = (userId, tablePresets) => ({
  type: UPDATE_TABLE_PRESETS,
  payload: { userId, tablePresets },
});

export const updateTablePresetsSuccess = (tablePresets) => ({
  type: UPDATE_TABLE_PRESETS_SUCCESS,
  payload: tablePresets,
});

export const updateTablePresetsError = (error) => ({
  type: UPDATE_TABLE_PRESETS_ERROR,
  payload: error,
});

export const setTableActivePreset = (userId, tableName, presetName) => ({
  type: SET_ACTIVE_PRESET,
  payload: { userId, tableName, presetName },
});

export const setTableActivePresetSuccess = (tablePresets) => ({
  type: SET_ACTIVE_PRESET_SUCCESS,
  payload: tablePresets,
});

export const setTableActivePresetError = (error) => ({
  type: SET_ACTIVE_PRESET_ERROR,
  payload: error,
});
