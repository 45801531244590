import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormMultiSelect } from "Components/Entity/FormMultiSelect";
import { getProgramPacketTemplateListData } from "store/programPacketTemplates/action";
import { getString } from "Components/Common/FormattedString";

const PackageFilter = ({ onChange, filters }) => {
  const dispatch = useDispatch();
  const { programPacketTemplatesData } = useSelector(
    (state) => state.ProgramPacketTemplates,
  );
  const [programPacketOptions, setProgramPacketOptions] = useState([]);

  useEffect(() => {
    dispatch(getProgramPacketTemplateListData());
  }, [dispatch]);

  useEffect(() => {
    const options = programPacketTemplatesData
      .filter((item) => item?.status !== "archived")
      .map((pp) => ({ value: pp.id, label: pp.label }));
    setProgramPacketOptions(options);
  }, [programPacketTemplatesData, filters]);

  const handleChange = (selectedValues) => {
    onChange("programPacketTemplate", selectedValues);
  };

  const programPacketTemplateArray = Array.isArray(
    filters.programPacketTemplate,
  )
    ? filters.programPacketTemplate
    : Object.values(filters.programPacketTemplate || {});

  return (
    <FormMultiSelect
      name="package"
      isClearable
      label={getString("programPacketTemplate")}
      values={programPacketTemplateArray}
      setValues={handleChange}
      options={programPacketOptions}
      data-testid="multi-select-filter-programPacketTemplate"
    />
  );
};

export default PackageFilter;
