export const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "8px",
  margin: `0 0 8px 0`,
  background: isDragging ? "lightgreen" : "grey",
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: "8px",
  width: "250px",
});

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const handleResetColumnSettings = (allColumns, setColumnOrder) => {
  allColumns.forEach((col) => col.toggleHidden(false));
  setColumnOrder();
};

export const handleToggleColumnVisibility = (allColumns, index) => {
  const column = allColumns[index];
  column.toggleHidden(!column.isVisible);
};

export const customSortMapping = {
  date: "dateStart",
};
