import React from "react";
import { useNavigate } from "react-router-dom";
import { getString } from "../../../../Components/Common/FormattedString";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";

const permissionsMap = {
  "/pp-applications": "PP_APPLICATION_READ",
  "/customers": "CUSTOMER_READ",
  "/pp-applications?filter=overdue": "PP_APPLICATION_READ",
  "/ip-participants": "IP_PARTICIPANT_READ",
  "/programs": "PROGRAM_READ",
  "/venues": "HOTEL_READ",
};

const QuickActions = () => {
  const navigate = useNavigate();

  const actions = [
    {
      icon: "ri-clipboard-line",
      label: getString("applications"),
      route: "/pp-applications",
    },
    {
      icon: "ri-user-line",
      label: getString("customers"),
      route: "/customers",
    },
    {
      icon: "ri-time-line",
      label: getString("overdue"),
      route: "/pp-applications?filter=overdue",
    },
    {
      icon: "ri-global-line",
      label: getString("ip-participants"),
      route: "/ip-participants",
    },
    {
      icon: "ri-space-ship-line",
      label: getString("programs"),
      route: "/programs",
    },
    {
      icon: "ri-building-line",
      label: getString("venues"),
      route: "/venues",
    },
  ];

  return (
    <React.Fragment>
      <div className="flex-grow-1">
        <p className="text-muted text-uppercase mb-2">
          {getString("quick_actions")}
        </p>
      </div>
      <div className="d-flex flex-column h-100">
        <div className="row g-3">
          {actions.map((action, index) => (
            <WithPermission
              key={index}
              permissionName={permissionsMap[action.route]}
            >
              <div className="col-6">
                <button
                  onClick={() => navigate(action.route)}
                  className="btn btn-light w-100 p-3 text-center d-flex flex-column align-items-center justify-content-center"
                >
                  <div className="mb-2">
                    <i className={action.icon + ` display-6 text-muted`}></i>
                  </div>
                  <span className="text-muted text-uppercase fs-13">
                    {action.label}
                  </span>
                </button>
              </div>
            </WithPermission>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuickActions;
