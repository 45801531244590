export const generateDefaultPreset = (tableName) => {
  return {
    tableName,
    presets: [
      {
        name: "default",
        hiddenColumns: [],
        sortBy: [],
        order: [],
      },
    ],
    activePreset: "default",
  };
};

export const defaultPresets = [
  generateDefaultPreset("applications"),
  generateDefaultPreset("customers"),
];
