import classNames from "classnames";
import { FormattedString } from "../FormattedString";
import "./ClearButton.scss";

export const ClearButton = ({
  clearStringId = "clear_value",
  disabled = false,
  onClick,
  "data-testid": dataTestId,
}) => {
  return (
    <button
      color="transparent"
      className={classNames([
        "clear-button",
        disabled && "clear-button--disabled",
      ])}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <span className="clear-button__icon">×</span>
      <FormattedString id={clearStringId} />
    </button>
  );
};
