import React, { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./AttachmentActionButtons.scss";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { FormattedString } from "Components/Common/FormattedString";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Modal, ModalBody } from "reactstrap";

const AttachmentActionButtons = ({
  onPreview,
  onDownload,
  onEdit,
  onArchive,
  onRestore,
  itemId,
  filePath,
  fileType,
}) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const previewButtonId = `previewButton-${itemId}`;
  const downloadButtonId = `downloadButton-${itemId}`;
  const editButtonId = `editButton-${itemId}`;
  const archiveButtonId = `archiveButton-${itemId}`;
  const restoreButtonId = `restoreButton-${itemId}`;

  useEffect(() => {
    if (isPreviewOpen) {
      document.documentElement.classList.add("no-overlay");
    } else {
      document.documentElement.classList.remove("no-overlay");
    }

    return () => {
      document.documentElement.classList.remove("no-overlay");
    };
  }, [isPreviewOpen]);

  const handlePreview = () => {
    if (fileType.toLowerCase() === "pdf") {
      setIsPdfModalOpen(true);
    } else {
      setIsPreviewOpen(true);
    }
    if (onPreview) {
      onPreview();
    }
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  const handleClosePdfModal = () => {
    setIsPdfModalOpen(false);
  };

  const renderPreview = () => {
    if (["jpg", "jpeg", "png", "gif"].includes(fileType.toLowerCase())) {
      return (
        <Lightbox mainSrc={filePath} onCloseRequest={handleClosePreview} />
      );
    } else {
      return (
        <div>
          <FormattedString id="preview_not_available" />
        </div>
      );
    }
  };

  return (
    <div className="attachment-action-buttons">
      {onPreview && (
        <div className="col-auto">
          <AngloTooltip stringId="preview">
            <button
              id={itemId && previewButtonId}
              type="button"
              className="btn btn-sm btn-soft-primary btn-icon"
              onClick={handlePreview}
            >
              <i className="ri-eye-line"></i>
            </button>
          </AngloTooltip>
        </div>
      )}
      {onDownload && (
        <div className="col-auto">
          <AngloTooltip stringId="download">
            <a
              id={itemId && downloadButtonId}
              type="button"
              className="btn btn-sm btn-soft-secondary btn-icon"
              href={filePath}
              download
              onClick={onDownload}
            >
              <i className="ri-download-line"></i>
            </a>
          </AngloTooltip>
        </div>
      )}
      {onEdit && (
        <div className="col-auto">
          <AngloTooltip stringId="edit">
            <button
              id={itemId && editButtonId}
              type="button"
              className="btn btn-sm btn-soft-warning btn-icon"
              onClick={onEdit}
            >
              <i className="ri-edit-2-line"></i>
            </button>
          </AngloTooltip>
        </div>
      )}
      {onArchive && (
        <div className="col-auto">
          <AngloTooltip stringId="archive">
            <button
              id={itemId && archiveButtonId}
              type="button"
              className="btn btn-sm btn-soft-danger btn-icon"
              onClick={onArchive}
            >
              <i className="ri-inbox-archive-line"></i>
            </button>
          </AngloTooltip>
        </div>
      )}
      {onRestore && (
        <div className="col-auto">
          <AngloTooltip stringId="restore">
            <button
              id={itemId && restoreButtonId}
              type="button"
              className="btn btn-sm btn-soft-success btn-icon"
              onClick={onRestore}
            >
              <i className="ri-inbox-unarchive-line"></i>
            </button>
          </AngloTooltip>
        </div>
      )}
      {isPreviewOpen && renderPreview()}
      <Modal isOpen={isPdfModalOpen} toggle={handleClosePdfModal} size="lg">
        <ModalBody>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}
          >
            <Viewer fileUrl={filePath} />
          </Worker>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AttachmentActionButtons;
