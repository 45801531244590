import React from "react";
import FormDateRange from "Components/Entity/FormDateRange";
import { getString } from "Components/Common/FormattedString";

const CreatedAtFilter = ({ onChange, filters }) => {
  const handleDateChange = (e, name) => {
    onChange("createdAt", {
      ...filters.createdAt,
      [name]: e.target.value || undefined,
    });
  };

  return (
    <FormDateRange
      label={getString("created_at")}
      startName="min"
      endName="max"
      startValue={filters.createdAt?.min || ""}
      endValue={filters.createdAt?.max || ""}
      onChange={handleDateChange}
      data-testid="date-filter-createdAt"
    />
  );
};

export default CreatedAtFilter;
