// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.minimalistic-button {
  border: solid 1px transparent;
  transition: all 0.3s ease;
  width: 100%;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding-left: 0;
  gap: 6px;
  background: none;
  text-decoration: none;
}
.minimalistic-button--default {
  color: var(--vz-default);
}
.minimalistic-button--primary {
  color: var(--vz-primary);
}
.minimalistic-button--secondary {
  color: var(--vz-secondary);
}
.minimalistic-button--danger {
  color: var(--vz-danger);
}
.minimalistic-button:hover {
  text-decoration: none;
}
.minimalistic-button--disabled {
  color: var(--vz-gray-600);
  cursor: not-allowed;
  opacity: 0.5;
}
.minimalistic-button--disabled:hover {
  color: var(--vz-gray-600);
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/MinimalisticButton/MinimalisticButton.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,QAAA;EACA,gBAAA;EACA,qBAAA;AACF;AACE;EACE,wBAAA;AACJ;AAEE;EACE,wBAAA;AAAJ;AAGE;EACE,0BAAA;AADJ;AAIE;EACE,uBAAA;AAFJ;AAKE;EACE,qBAAA;AAHJ;AAME;EACE,yBAAA;EACA,mBAAA;EACA,YAAA;AAJJ;AAMI;EACE,yBAAA;EACA,qBAAA;AAJN","sourcesContent":[".minimalistic-button {\n  border: solid 1px transparent;\n  transition: all 0.3s ease;\n  width: 100%;\n  font-size: 15px;\n  display: flex;\n  align-items: center;\n  padding-left: 0;\n  gap: 6px;\n  background: none;\n  text-decoration: none;\n\n  &--default {\n    color: var(--vz-default);\n  }\n\n  &--primary {\n    color: var(--vz-primary);\n  }\n\n  &--secondary {\n    color: var(--vz-secondary);\n  }\n\n  &--danger {\n    color: var(--vz-danger);\n  }\n\n  &:hover {\n    text-decoration: none;\n  }\n\n  &--disabled {\n    color: var(--vz-gray-600);\n    cursor: not-allowed;\n    opacity: 0.5;\n\n    &:hover {\n      color: var(--vz-gray-600);\n      text-decoration: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
