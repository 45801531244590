import { APIClient } from "../api_helper";
import formatter from "sprintf-js";

import * as url from "./endpoints_helper";
import { convertFiltersToQueryString } from "helpers/utils";

const csApiClient = new APIClient(process.env.REACT_APP_CORE_SERVICE_API_URL);

export const setAuthToken = (token) => csApiClient.setAuthorization(token);

// Business Unit
export const getBusinessUnitList = () =>
  csApiClient.get(url.GET_BUSINESS_UNIT_LIST);

// Dashboard
export const getDashboardData = () =>
  csApiClient.get(url.GET_ANALYTICS__DASHBOARD_DATA);

// Hotels
export const getHotelList = () => csApiClient.get(url.GET_HOTEL_LIST);
export const postHotel = (data, onSuccess) =>
  csApiClient
    .create(url.POST_HOTEL, data, onSuccess)
    .then(() => onSuccess && onSuccess());
export const editHotel = (id, data, onSuccess) =>
  csApiClient
    .put(formatter.sprintf(url.EDIT_HOTEL, id), data)
    .then(() => onSuccess && onSuccess());
export const archiveHotel = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.ARCHIVE_HOTEL, id))
    .then(() => onSuccess && onSuccess());
export const restoreHotel = (id, onSuccess) =>
  csApiClient
    .update(formatter.sprintf(url.RESTORE_HOTEL, id))
    .then(() => onSuccess && onSuccess());

// Meeting Points
export const getMeetingPointList = () =>
  csApiClient.get(url.GET_MEETING_POINT_LIST);
export const postMeetingPoint = (data, onSuccess) =>
  csApiClient
    .create(url.POST_MEETING_POINT, data)
    .then(() => onSuccess && onSuccess());
export const editMeetingPoint = (id, data, onSuccess) =>
  csApiClient
    .put(formatter.sprintf(url.EDIT_MEETING_POINT, id), data)
    .then(() => onSuccess && onSuccess());
export const archiveMeetingPoint = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.ARCHIVE_MEETING_POINT, id))
    .then(() => onSuccess && onSuccess());
export const restoreMeetingPoint = (id, onSuccess) =>
  csApiClient
    .update(formatter.sprintf(url.RESTORE_MEETING_POINT, id))
    .then(() => onSuccess && onSuccess());

// Meetings
export const getMeetingList = () => csApiClient.get(url.GET_MEETING_LIST);
export const postMeeting = (data, onSuccess) =>
  csApiClient
    .create(url.POST_MEETING, data)
    .then(() => onSuccess && onSuccess());
export const editMeeting = (id, data, onSuccess) =>
  csApiClient
    .put(formatter.sprintf(url.EDIT_MEETING, id), data)
    .then(() => onSuccess && onSuccess());
export const archiveMeeting = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.ARCHIVE_MEETING, id))
    .then(() => onSuccess && onSuccess());
export const restoreMeeting = (id, onSuccess) =>
  csApiClient
    .update(formatter.sprintf(url.RESTORE_MEETING, id))
    .then(() => onSuccess && onSuccess());

// Coordinators
export const getCoordinatorList = () =>
  csApiClient.get(url.GET_COORDINATOR_LIST);
export const postCoordinator = (data, onSuccess) =>
  csApiClient
    .create(url.POST_COORDINATOR, data, onSuccess)
    .then(() => onSuccess && onSuccess());
export const editCoordinator = (id, data, onSuccess) =>
  csApiClient
    .put(formatter.sprintf(url.EDIT_COORDINATOR, id), data)
    .then(() => onSuccess && onSuccess());
export const archiveCoordinator = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.ARCHIVE_COORDINATOR, id))
    .then(() => onSuccess && onSuccess());
export const restoreCoordinator = (id, onSuccess) =>
  csApiClient
    .update(formatter.sprintf(url.RESTORE_COORDINATOR, id))
    .then(() => onSuccess && onSuccess());

// Programs
export const getProgramList = () => csApiClient.get(url.GET_PROGRAM_LIST);
export const postProgram = (data, onSuccess) =>
  csApiClient
    .create(url.POST_PROGRAM, data, onSuccess)
    .then(() => onSuccess && onSuccess());
export const editProgram = (id, data, onSuccess) =>
  csApiClient
    .put(formatter.sprintf(url.EDIT_PROGRAM, id), data)
    .then(() => onSuccess && onSuccess());
export const deleteProgram = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.DELETE_PROGRAM, id))
    .then(() => onSuccess && onSuccess());
export const duplicateProgram = (id, onSuccess) =>
  csApiClient
    .create(formatter.sprintf(url.DUPLICATE_PROGRAM, id))
    .then((r) => onSuccess && onSuccess(r));
export const getProgram = (id) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(formatter.sprintf(url.GET_PROGRAM, id))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const addProgramPacket = (programId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .create(formatter.sprintf(url.ADD_PROGRAM_PACKET, programId), data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const updateProgramPacket = (programId, programPacketId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .put(
        formatter.sprintf(
          url.UPDATE_PROGRAM_PACKET,
          programId,
          programPacketId,
        ),
        data,
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const deleteProgramPacket = (programId, programPacketId) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(
        formatter.sprintf(
          url.DELETE_PROGRAM_PACKET,
          programId,
          programPacketId,
        ),
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const addInstallmentPlan = (programId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(formatter.sprintf(url.ADD_INSTALLMENT_PLAN, programId), data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const detachInstallmentPlan = (programId, installmentPlanId) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(
        formatter.sprintf(
          url.DETACH_INSTALLMENT_PLAN,
          programId,
          installmentPlanId,
        ),
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const definePricesForPacketInstallment = (
  programId,
  programPacketId,
  data,
) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(
        formatter.sprintf(
          url.DEFINE_PRICES_FOR_PACKET_INSTALLMENT,
          programId,
          programPacketId,
        ),
        data,
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const assignUpsellToProgram = (programId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(formatter.sprintf(url.ASSIGN_PROGRAM_UPSELL, programId), data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const detachUpsellFromProgram = (programId, variant, upsellId) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(
        formatter.sprintf(
          url.DETACH_PROGRAM_UPSELL,
          programId,
          variant,
          upsellId,
        ),
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const assignHotelToProgram = (programId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(formatter.sprintf(url.ASSIGN_PROGRAM_HOTEL, programId), data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const detachHotelFromProgram = (programId, hotelId) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(formatter.sprintf(url.DETACH_PROGRAM_HOTEL, programId, hotelId))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const assignCoordinatorToProgram = (
  programId,
  programPacketId,
  data,
) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(
        formatter.sprintf(url.ASSIGN_COORDINATOR, programId, programPacketId),
        data,
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const detachCoordinatorFromProgram = (
  programId,
  programPacketId,
  coordinatorId,
) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(
        formatter.sprintf(
          url.DETACH_COORDINATOR,
          programId,
          programPacketId,
          coordinatorId,
        ),
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const assignHealthAndSafetyToProgram = (programId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(formatter.sprintf(url.ASSIGN_HEALTH_AND_SAFETY, programId), data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const detachHealthAndSafetyFromProgram = (
  programId,
  healthAndSafetyId,
) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(
        formatter.sprintf(
          url.DETACH_HEALTH_AND_SAFETY,
          programId,
          healthAndSafetyId,
        ),
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const addMeetingToProgram = (programId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .create(formatter.sprintf(url.CREATE_PROGRAM_MEETING, programId), data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const editMeetingInProgram = (programId, meetingId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .put(
        formatter.sprintf(url.UPDATE_PROGRAM_MEETING, programId, meetingId),
        data,
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const deleteMeetingFromProgram = (programId, variant) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(formatter.sprintf(url.DELETE_PROGRAM_MEETING, programId, variant))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const updateProgramStatus = (programId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(formatter.sprintf(url.UPDATE_PROGRAM_STATUS, programId), data)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

export const addDiscountToProgram = (programId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .create(formatter.sprintf(url.ADD_PROGRAM_DISCOUNT, programId), data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const updateDiscountInProgram = (programId, discountId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .put(
        formatter.sprintf(url.UPDATE_PROGRAM_DISCOUNT, programId, discountId),
        data,
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const deleteProgramDiscount = (programId, programPacketId) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(
        formatter.sprintf(
          url.DELETE_PROGRAM_DISCOUNT,
          programId,
          programPacketId,
        ),
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const generateProgramReport = () => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(url.GENERATE_PROGRAM_REPORT, { responseType: "blob" })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

// Payments
export const generatePaymentReport = () => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(url.GENERATE_PAYMENT_REPORT, { responseType: "blob" })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const generateNewPaymentReport = (from, to) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(`${url.GENERATE_PAYMENT_REPORT_NEW}`, {
        startDate: from,
        endDate: to,
        responseType: "blob",
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

// Attachments
export const getAttachmentList = () => csApiClient.get(url.GET_ATTACHMENT_LIST);
export const postAttachment = (data, onSuccess) =>
  csApiClient
    .create(url.POST_ATTACHMENT, data, onSuccess)
    .then(() => onSuccess && onSuccess());
export const editAttachment = (id, data, onSuccess) =>
  csApiClient
    .put(formatter.sprintf(url.EDIT_ATTACHMENT, id), data)
    .then(() => onSuccess && onSuccess());
export const archiveAttachment = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.ARCHIVE_ATTACHMENT, id))
    .then(() => onSuccess && onSuccess());
export const restoreAttachment = (id, onSuccess) =>
  csApiClient
    .update(formatter.sprintf(url.RESTORE_ATTACHMENT, id))
    .then(() => onSuccess && onSuccess());

// PaymentMethods
export const getPaymentMethodList = () =>
  csApiClient.get(url.GET_PAYMENT_METHOD_LIST);

// Upsells
export const getUpsellList = () => csApiClient.get(url.GET_UPSELL_LIST);
export const postUpsell = (data, onSuccess) =>
  csApiClient
    .create(url.POST_UPSELL, data, onSuccess)
    .then(() => onSuccess && onSuccess());
export const editUpsell = (id, data, onSuccess) =>
  csApiClient
    .put(formatter.sprintf(url.EDIT_UPSELL, id), data)
    .then(() => onSuccess && onSuccess());
export const archiveUpsell = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.ARCHIVE_UPSELL, id))
    .then(() => onSuccess && onSuccess());
export const restoreUpsell = (id, onSuccess) =>
  csApiClient
    .update(formatter.sprintf(url.RESTORE_UPSELL, id))
    .then(() => onSuccess && onSuccess());
export const addUpsellAttachments = (id, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(formatter.sprintf(url.ADD_UPSELL_ATTACHMENTS, id), data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const removeUpsellAttachment = (upsellId, attachmentId) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(
        formatter.sprintf(url.REMOVE_UPSELL_ATTACHMENT, upsellId, attachmentId),
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

// Applications
export const getApplicationsList = (
  page = 1,
  pageSize = 10,
  searchTerm = "",
  orderBy,
  orderDirection,
  filters,
) => {
  let urlWithParams = `${url.GET_APPLICATIONS_LIST}?page=${page}&pageSize=${pageSize}`;
  if (searchTerm) {
    urlWithParams += `&filters[searchTerm]=${encodeURIComponent(searchTerm)}`;
  }
  if (orderBy) {
    urlWithParams += `&filters[orderBy]=${encodeURIComponent(orderBy)}`;
  }
  if (orderDirection) {
    urlWithParams += `&filters[orderDirection]=${encodeURIComponent(orderDirection)}`;
  }
  if (filters) {
    const filtersQueryString = convertFiltersToQueryString(filters);
    if (filtersQueryString) {
      urlWithParams += `&${filtersQueryString}`;
    }
  }
  return csApiClient.get(urlWithParams);
};

export const addEntityNote = (entityId, type, content, name) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .create(formatter.sprintf(url.POST_ENTITY_NOTE), {
        entityId,
        type,
        content,
        name,
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const deleteEntityNote = (noteId) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(formatter.sprintf(url.DELETE_ENTITY_NOTE, noteId))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getApplication = (id) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(formatter.sprintf(url.GET_APPLICATION, id))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getApplicationActivity = (id) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(formatter.sprintf(url.GET_APPLICATION_ACTIVITY, id))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getApplicationActivityEmail = (applicationId, emailId) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(
        formatter.sprintf(
          url.GET_APPLICATION_ACTIVITY_EMAIL,
          applicationId,
          emailId,
        ),
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const updateApplicationStatus = (applicationId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(
        formatter.sprintf(url.UPDATE_APPLICATION_STATUS, applicationId),
        data,
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

export const addApplicationInvitation = (applicationId, email) => {
  var participants = { email };
  return new Promise((resolve, reject) => {
    csApiClient
      .create(
        formatter.sprintf(url.POST_APPLICATION_INVITATION, applicationId),
        {
          participants,
        },
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const updateApplicationState = (applicationId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .put(formatter.sprintf(url.UPDATE_APPLICATION_STATE, applicationId), data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const postApplication = (data, onSuccess) =>
  csApiClient.create(url.POST_APPLICATION, data, onSuccess).then((r) => {
    onSuccess && onSuccess(r);
  });

export const generateApplicationReport = () => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(url.GENERATE_APPLICATION_REPORT, { responseType: "blob" })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const generateApplicationAbandonedReport = () => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(url.GENERATE_APPLICATION_REPORT, {
        onlyAbandoned: "true",
        responseType: "blob",
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

// Applications
export const getRecentApplicationsData = (
  page = 1,
  pageSize = 5,
  searchTerm = "",
) => {
  return new Promise((resolve, reject) => {
    let urlWithParams = `${url.GET_APPLICATIONS_LIST}?page=${page}&pageSize=${pageSize}`;
    if (searchTerm) {
      urlWithParams += `&searchTerm=${encodeURIComponent(searchTerm)}`;
    }

    csApiClient
      .get(urlWithParams)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getAbandonedApplicationsData = () => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(url.GET_APPLICATIONS_LIST, {
        onlyAbandoned: "true",
        pageSize: 5,
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

// Customers
export const getCustomersList = (
  page = 1,
  pageSize = 10,
  searchTerm = "",
  orderBy,
  orderDirection,
  filters,
) => {
  let urlWithParams = `${url.GET_CUSTOMER_LIST}?page=${page}&pageSize=${pageSize}`;
  if (searchTerm) {
    urlWithParams += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  }
  if (orderBy) {
    urlWithParams += `&orderBy=${encodeURIComponent(orderBy)}`;
  }
  if (orderDirection) {
    urlWithParams += `&orderDirection=${encodeURIComponent(orderDirection)}`;
  }
  if (filters) {
    const filtersQueryString = convertFiltersToQueryString(filters);
    if (filtersQueryString) {
      urlWithParams += `&${filtersQueryString}`;
    }
  }
  return csApiClient.get(urlWithParams);
};

export const getCustomer = (id) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(formatter.sprintf(url.GET_CUSTOMER, id))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getCustomerApplications = (id, page = 1) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(
        formatter.sprintf(url.GET_APPLICATIONS_LIST, id) +
          `?customerId=${id}&page=${page}`,
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getCustomerParticipants = (id) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(formatter.sprintf(url.GET_CUSTOMER_PARTICIPANTS, id))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getCustomerPayerDetails = (id) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(formatter.sprintf(url.GET_CUSTOMER_PAYER_DETAILS, id))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

// Ip Applications
export const getIpApplicationsList = (page = 1, searchTerm = "") => {
  let urlWithParams = `${url.GET_IP_APPLICATIONS_LIST}?page=${page}`;
  if (searchTerm) {
    urlWithParams += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  }
  return csApiClient.get(urlWithParams);
};

export const getIpApplication = (id) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(formatter.sprintf(url.GET_IP_APPLICATION, id))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getIpApplicationActivity = (id) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(formatter.sprintf(url.GET_IP_APPLICATION_ACTIVITY, id))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getIpApplicationActivityEmail = (applicationId, emailId) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(
        formatter.sprintf(
          url.GET_IP_APPLICATION_ACTIVITY_EMAIL,
          applicationId,
          emailId,
        ),
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const updateIpApplicationStatus = (
  programPacketId,
  internationalParticipantId,
  status,
) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(
        formatter.sprintf(
          url.UPDATE_IP_APPLICATION_STATUS,
          programPacketId,
          internationalParticipantId,
        ),
        { status },
      )
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateIpApplicationState = (applicationId, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .put(
        formatter.sprintf(url.UPDATE_IP_APPLICATION_STATE, applicationId),
        data,
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const postIpApplication = (data, onSuccess) =>
  csApiClient.create(url.POST_IP_APPLICATION, data, onSuccess).then((r) => {
    onSuccess && onSuccess(r);
  });

export const generateIpApplicationReport = () => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(url.GENERATE_IP_APPLICATION_REPORT, { responseType: "blob" })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

// ProgramPacketTemplates
export const getProgramPacketTemplateList = () =>
  csApiClient.get(url.GET_PROGRAM_PACKET_TEMPLATE_LIST);
export const postProgramPacketTemplate = (data, onSuccess) =>
  csApiClient
    .create(url.POST_PROGRAM_PACKET_TEMPLATE, data, onSuccess)
    .then(() => onSuccess && onSuccess());
export const editProgramPacketTemplate = (id, data, onSuccess) =>
  csApiClient
    .put(formatter.sprintf(url.EDIT_PROGRAM_PACKET_TEMPLATE, id), data)
    .then(() => onSuccess && onSuccess());
export const archiveProgramPacketTemplate = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.ARCHIVE_PROGRAM_PACKET_TEMPLATE, id))
    .then(() => onSuccess && onSuccess());
export const restoreProgramPacketTemplate = (id, onSuccess) =>
  csApiClient
    .update(formatter.sprintf(url.RESTORE_PROGRAM_PACKET_TEMPLATE, id))
    .then(() => onSuccess && onSuccess());

export const addProgramPacketTemplateAttachments = (id, data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(
        formatter.sprintf(url.ADD_PROGRAM_PACKET_TEMPLATE_ATTACHMENTS, id),
        data,
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const removeProgramPacketTemplateAttachment = (
  programPacketId,
  attachmentId,
) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(
        formatter.sprintf(
          url.REMOVE_PROGRAM_PACKET_TEMPLATE_ATTACHMENT,
          programPacketId,
          attachmentId,
        ),
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

// Points of Sell
export const getPointOfSellList = () =>
  csApiClient.get(url.GET_POINT_OF_SELL_LIST);

// InstallmentPlans
export const getInstallmentPlanList = () =>
  csApiClient.get(url.GET_INSTALLMENT_PLAN_LIST);
export const postInstallmentPlan = (data, onSuccess) =>
  csApiClient
    .create(url.POST_INSTALLMENT_PLAN, data, onSuccess)
    .then(() => onSuccess && onSuccess());
export const postInstallmentPlanPromise = (data) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .create(url.POST_INSTALLMENT_PLAN, data)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const deleteInstallmentPlan = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.DELETE_INSTALLMENT_PLAN, id))
    .then(() => onSuccess && onSuccess());

// IpParticipants
export const getIpParticipantList = () =>
  csApiClient.get(url.GET_IP_PARTICIPANT_LIST);
export const postIpParticipant = (data, onSuccess) =>
  csApiClient
    .create(url.POST_IP_PARTICIPANT, data, onSuccess)
    .then(() => onSuccess && onSuccess());
export const editIpParticipant = (id, data, onSuccess) =>
  csApiClient
    .put(formatter.sprintf(url.EDIT_IP_PARTICIPANT, id), data)
    .then(() => onSuccess && onSuccess());
export const deleteIpParticipant = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.ARCHIVE_IP_PARTICIPANT, id))
    .then(() => onSuccess && onSuccess());
export const restoreIpParticipant = (id, onSuccess) =>
  csApiClient
    .update(formatter.sprintf(url.RESTORE_IP_PARTICIPANT, id))
    .then(() => onSuccess && onSuccess());
export const toggleIpParticipantStatus = (id, onSuccess) =>
  csApiClient
    .delete(formatter.sprintf(url.TOGGLE_IP_PARTICIPANT_STATUS, id))
    .then(() => onSuccess && onSuccess());
export const postIpAttachment = (id, data, onSuccess) =>
  csApiClient
    .create(formatter.sprintf(url.POST_IP_ATTACHMENT, id), data, onSuccess)
    .then(() => onSuccess && onSuccess());
export const removeIpAttachment = (participantId, attachmentId, onSuccess) =>
  csApiClient
    .delete(
      formatter.sprintf(url.REMOVE_IP_ATTACHMENT, participantId, attachmentId),
    )
    .then(() => onSuccess && onSuccess());
export const assignIpToProgramPacket = (programPacketId, values) =>
  csApiClient
    .update(
      formatter.sprintf(url.ASSIGN_IP_TO_PROGRAM_PACKET, programPacketId),
      values,
    )
    .then((response) => {
      return response;
    });
export const removeIpFromProgramPacket = (
  programPacketId,
  internationalParticipantId,
  onSuccess,
) =>
  csApiClient
    .delete(
      formatter.sprintf(
        url.REMOVE_IP_FROM_PROGRAM_PACKET,
        programPacketId,
        internationalParticipantId,
      ),
    )
    .then(() => onSuccess && onSuccess());
export const generateIpReport = () => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(url.GENERATE_IP_REPORT, { responseType: "blob" })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const getIpParticipant = (id) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(formatter.sprintf(url.GET_IP_PARTICIPANT, id))
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const updateIpStatus = (programPacketId, participantId, status) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .update(
        formatter.sprintf(url.UPDATE_IP_STATUS, programPacketId, participantId),
        { status },
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const getIpParticipantApplications = (id, page = 1, searchTerm = "") => {
  let urlWithParams = `${url.GET_IP_PARTICIPANT_APPLICATIONS}?internationalParticipantId=${id}&page=${page}`;
  if (searchTerm) {
    urlWithParams += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  }
  return new Promise((resolve, reject) => {
    csApiClient
      .get(urlWithParams)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export const addDocumentToIP = (participantId, documentData) => {
  const urlWithParams = formatter.sprintf(
    url.ADD_DOCUMENT_TO_IP,
    participantId,
  );
  return new Promise((resolve, reject) => {
    csApiClient
      .create(urlWithParams, documentData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const editDocumentForIP = (participantId, documentId, documentData) => {
  const urlWithParams = formatter.sprintf(
    url.EDIT_DOCUMENT_FOR_IP,
    participantId,
    documentId,
  );
  return new Promise((resolve, reject) => {
    csApiClient
      .put(urlWithParams, documentData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const archiveDocumentOfIp = (participantId, documentId) => {
  const urlWithParams = formatter.sprintf(
    url.ARCHIVE_DOCUMENT_OF_IP,
    participantId,
    documentId,
  );
  return new Promise((resolve, reject) => {
    csApiClient
      .delete(urlWithParams)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const restoreDocumentOfIp = (participantId, documentId) => {
  const urlWithParams = formatter.sprintf(
    url.RESTORE_DOCUMENT_OF_IP,
    participantId,
    documentId,
  );
  return new Promise((resolve, reject) => {
    csApiClient
      .update(urlWithParams)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

// BillingData
export const getBillingDataList = (
  page = 1,
  searchTerm = "",
  pageSize = null,
) => {
  let urlWithParams = `${url.GET_BILLING_DATA_LIST}?page=${page}`;
  if (searchTerm) {
    urlWithParams += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  }
  if (pageSize) {
    urlWithParams += `&pageSize=${pageSize}`;
  }
  return csApiClient.get(urlWithParams);
};

export const getBillingDataApplications = (id, page = 1) => {
  return new Promise((resolve, reject) => {
    csApiClient
      .get(
        formatter.sprintf(url.GET_APPLICATIONS_LIST, id) +
          `?billingDataId=${id}&page=${page}`,
      )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
