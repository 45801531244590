import {
  call,
  put,
  takeEvery,
  all,
  fork,
  takeLatest,
} from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

import { GET_APPLICATIONS_LIST_DATA, POST_APPLICATION } from "./actionType";
import { applicationsApiSuccess, applicationsApiError } from "./action";

import {
  getApplicationsList,
  postApplication,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getApplicationsListData({ payload: data }) {
  try {
    const { page, pageSize, searchTerm, orderBy, orderDirection, filters } =
      data;
    const response = yield call(
      getApplicationsList,
      page,
      pageSize,
      searchTerm,
      orderBy,
      orderDirection,
      filters,
    );

    yield put(applicationsApiSuccess(GET_APPLICATIONS_LIST_DATA, response));
  } catch (error) {
    yield put(applicationsApiError(GET_APPLICATIONS_LIST_DATA, error));
  }
}

function* createApplication({ payload: application }) {
  try {
    const response = yield call(
      postApplication,
      application.data,
      application.onSuccess,
    );
    yield put(applicationsApiSuccess(POST_APPLICATION, response));
    toast_success("Application added successfully");
  } catch (error) {
    yield put(applicationsApiError(POST_APPLICATION, error));
    toast_error(error.data.message);
  }
}

export function* watchGetApplicationsListData() {
  yield takeLatest(GET_APPLICATIONS_LIST_DATA, getApplicationsListData);
}

export function* watchPostApplication() {
  yield takeEvery(POST_APPLICATION, createApplication);
}

function* applicationsSaga() {
  yield all([fork(watchGetApplicationsListData), fork(watchPostApplication)]);
}

export default applicationsSaga;
