import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

// Crypto Redux States
import {
  GET_UPSELL_LIST_DATA,
  POST_UPSELL,
  EDIT_UPSELL,
  ARCHIVE_UPSELL,
  RESTORE_UPSELL,
} from "./actionType";
import { upsellsApiSuccess, upsellsApiError } from "./action";

//Include Both Helper File with needed methods
import {
  getUpsellList,
  postUpsell,
  editUpsell,
  archiveUpsell,
  restoreUpsell,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getUpsellListData({ payload: data }) {
  try {
    const response = yield call(getUpsellList, data);

    yield put(upsellsApiSuccess(GET_UPSELL_LIST_DATA, response));
  } catch (error) {
    yield put(upsellsApiError(GET_UPSELL_LIST_DATA, error));
  }
}

function* createUpsell({ payload: upsell }) {
  try {
    const response = yield call(postUpsell, upsell.data, upsell.onSuccess);
    yield put(upsellsApiSuccess(POST_UPSELL, response));
    toast_success("Upsell added successfully");
  } catch (error) {
    yield put(upsellsApiError(POST_UPSELL, error));
    toast_error(error.data.message);
  }
}

function* editUpsellEntry({ payload: upsell }) {
  try {
    const response = yield call(
      editUpsell,
      upsell.id,
      upsell.data,
      upsell.onSuccess,
    );
    yield put(upsellsApiSuccess(EDIT_UPSELL, response));
    toast_success("Upsell edited successfully");
  } catch (error) {
    yield put(upsellsApiError(EDIT_UPSELL, error));
    toast_error(error.data.message);
  }
}

function* archiveUpsellEntry({ payload: upsell }) {
  try {
    const response = yield call(archiveUpsell, upsell.id, upsell.onSuccess);
    yield put(upsellsApiSuccess(ARCHIVE_UPSELL, response));
    toast_success("Upsell removed");
  } catch (error) {
    yield put(upsellsApiError(ARCHIVE_UPSELL, error));
    toast_error(error.data.message);
  }
}

function* restoreUpsellEntry({ payload: upsell }) {
  try {
    const response = yield call(restoreUpsell, upsell.id, upsell.onSuccess);
    yield put(upsellsApiSuccess(RESTORE_UPSELL, response));
    toast_success("Upsell restored");
  } catch (error) {
    yield put(upsellsApiError(RESTORE_UPSELL, error));
    toast_error(error.data.message);
  }
}

export function* watchGetUpsellListData() {
  yield takeEvery(GET_UPSELL_LIST_DATA, getUpsellListData);
}

export function* watchPostUpsell() {
  yield takeEvery(POST_UPSELL, createUpsell);
}
export function* watchEditUpsell() {
  yield takeEvery(EDIT_UPSELL, editUpsellEntry);
}
export function* watchArchiveUpsell() {
  yield takeEvery(ARCHIVE_UPSELL, archiveUpsellEntry);
}
export function* watchRestoreUpsell() {
  yield takeEvery(RESTORE_UPSELL, restoreUpsellEntry);
}

function* upsellsSaga() {
  yield all([
    fork(watchGetUpsellListData),
    fork(watchPostUpsell),
    fork(watchEditUpsell),
    fork(watchArchiveUpsell),
    fork(watchRestoreUpsell),
  ]);
}

export default upsellsSaga;
