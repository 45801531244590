import React from "react";
import { AngloTooltip } from "../AngloTooltip/AngloTooltip";
import "./InternationalParticipantEquation.scss";
import { getString } from "../FormattedString";

const colorMap = {
  non_ns_ip: "#ff0000",
  ns: "#eaaa00",
  esl: "#45cb85",
};

export const InternationalParticipantEquation = ({ summary }) => {
  const { quantity, totalCapacity } = summary;
  const totalSum = quantity.reduce((sum, item) => sum + item.count, 0);

  return (
    <div className="international-participant-equation">
      {totalSum > 0 && (
        <>
          {quantity.map((item, index) => (
            <React.Fragment key={index}>
              <AngloTooltip text={getString(item.type)}>
                <span
                  className={`international-participant-equation__val international-participant-equation__val--${item.type}`}
                  style={{ color: colorMap[item.type] || "defaultColor" }}
                >
                  {item.count}
                </span>
              </AngloTooltip>
              {index < quantity.length - 1 && (
                <span className="international-participant-equation__sign">
                  +
                </span>
              )}
            </React.Fragment>
          ))}
          <span className="international-participant-equation__sign">=</span>
        </>
      )}
      <AngloTooltip text={getString("total_sum")}>
        <span className="international-participant-equation__val international-participant-equation__val--sum">
          {totalSum}
        </span>
      </AngloTooltip>
      <span className="international-participant-equation__sign international-participant-equation__sign--bright">
        /
      </span>
      <AngloTooltip text={getString("total_capacity")}>
        <span
          className={`international-participant-equation__val international-participant-equation__val--total`}
          style={{ color: colorMap.totalCapacity || "defaultColor" }}
        >
          {totalCapacity}
        </span>
      </AngloTooltip>
    </div>
  );
};
