import React, { useEffect } from "react";
import "./App.scss";
import "./assets/scss/themes.scss";
import Route from "./Routes";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import strings from "resources/strings";

i18n.use(initReactI18next).init({
  resources: strings,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    let faviconPath = `${process.env.PUBLIC_URL}/favicon.ico`;

    if (environment === "staging") {
      faviconPath = `${process.env.PUBLIC_URL}/fav-staging.ico`;
    } else if (environment === "local") {
      faviconPath = `${process.env.PUBLIC_URL}/fav-local.ico`;
    }

    const link = document.createElement("link");
    link.rel = "icon";
    link.href = faviconPath;
    document.head.appendChild(link);
  }, []);

  return <Route />;
}

export default App;
