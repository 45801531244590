import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card, CardBody, Form, Collapse, Label, Input } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import FormTextField from "Components/Entity/FormTextField";
import { handleInputChange } from "helpers/validation_helper";
import { usePresetTableContext } from "../PresetTableContext";
import { useDispatch, useSelector } from "react-redux";
import { updateTablePresets } from "store/actions";
import PresetTableFilters from "./PresetTableFilters";
import { DEFAULT_PAGE_SIZE } from "resources/consts";
import { FormattedString, getString } from "Components/Common/FormattedString";

export const AddPresetCanvas = ({ visible, setVisible }) => {
  const { user } = useSelector((state) => state.Profile);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({});
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [openSection, setOpenSection] = useState("");

  const {
    tableName,
    presets,
    allColumns: contextAllColumns,
    filterMapping,
    handlePageChange,
  } = usePresetTableContext();
  const [allColumns, setAllColumns] = useState(
    contextAllColumns.map((col) => ({ ...col, isVisible: true })),
  );

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      presetName: "",
    },
    validationSchema: Yup.object({
      presetName: Yup.string()
        .required(getString("preset_name_required"))
        .test(
          "unique-name",
          getString("preset_name_exists"),
          (value) =>
            !presets.some(
              (preset) => preset.name.toLowerCase() === value.toLowerCase(),
            ),
        )
        .notOneOf(["default", "Default"], getString("preset_name_default")),
    }),
    onSubmit: (values) => {
      const newPreset = {
        name: values.presetName,
        hiddenColumns: allColumns.filter((c) => !c.isVisible).map((c) => c.id),
        sortBy: [],
        order: [...allColumns.map((c) => c.id), "Actions"],
        filters,
        pageSize,
      };

      const updatedTablePresets = user.tablePresets.map((preset) =>
        preset.tableName === tableName
          ? {
              ...preset,
              presets: [...preset.presets, newPreset],
              activePreset: values.presetName,
            }
          : preset,
      );

      if (
        !updatedTablePresets.some((preset) => preset.tableName === tableName)
      ) {
        updatedTablePresets.push({
          tableName,
          presets: [newPreset],
          activePreset: values.presetName,
        });
      }

      dispatch(updateTablePresets(user.id, updatedTablePresets));
      handlePageChange(1);
      setVisible(false);
    },
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 8px 0`,
    background: isDragging ? "#f0f0f0" : "#fff",
    border: "1px solid #ddd",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: isDragging ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
    transition: "background-color 0.2s ease",
    ...draggableStyle,
  });

  const getListStyle = () => ({
    width: "100%",
  });

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedColumns = Array.from(allColumns);
    const [removed] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, removed);

    setAllColumns(reorderedColumns);
  };

  const handleToggleColumnVisibility = (index) => {
    const updatedColumns = [...allColumns];
    updatedColumns[index].isVisible = !updatedColumns[index].isVisible;
    setAllColumns(updatedColumns);
  };

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(!visible)}
      title={getString("add_preset_title")}
      formId="add-preset"
      validationRule={validation.isValid}
      buttonLabel={getString("add_button_label")}
    >
      <Form
        id="add-preset"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Card>
          <CardBody className="card-body">
            <FormTextField
              label={getString("preset_name_label")}
              name="presetName"
              value={validation.values.presetName}
              onChange={(e) => handleInputChange(validation, e, "presetName")}
              error={validation.errors.presetName}
              touched={validation.touched.presetName}
              data-testid="preset-name-input"
            />
          </CardBody>
        </Card>
      </Form>
      <Card>
        <CardBody className="card-body">
          <Label
            onClick={() =>
              setOpenSection(openSection === "columns" ? "" : "columns")
            }
            style={{
              marginBottom: "1rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormattedString id="column_order_visibility" />
            <i
              className={`ri-arrow-${openSection === "columns" ? "up" : "down"}-s-line`}
              style={{ marginLeft: "auto", fontSize: "18px" }}
            ></i>
          </Label>
          <Collapse isOpen={openSection === "columns"}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="columns">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {allColumns
                      .filter((c) => c.id !== "Actions")
                      .map((column, index) => (
                        <Draggable
                          key={column.id}
                          draggableId={column.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                              className={`d-flex align-items-center mb-2 gap-2 draggable-item ${
                                snapshot.isDragging ? "dragging" : ""
                              }`}
                            >
                              <input
                                type="checkbox"
                                checked={column.isVisible}
                                onChange={() =>
                                  handleToggleColumnVisibility(index)
                                }
                              />{" "}
                              {column.Header}
                              <span
                                className="drag-handle"
                                style={{
                                  cursor: "grab",
                                  marginLeft: "auto",
                                  padding: "0 8px",
                                  color: "#888",
                                }}
                              >
                                &#x2630;
                              </span>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Collapse>
        </CardBody>
      </Card>
      {filterMapping && (
        <Card>
          <CardBody className="card-body">
            <Label
              onClick={() =>
                setOpenSection(openSection === "filters" ? "" : "filters")
              }
              style={{
                marginBottom: "1rem",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormattedString id="filter_settings" />
              <i
                className={`ri-arrow-${openSection === "filters" ? "up" : "down"}-s-line`}
                style={{ marginLeft: "auto", fontSize: "18px" }}
              ></i>
            </Label>
            <Collapse isOpen={openSection === "filters"}>
              <PresetTableFilters filters={filters} setFilters={setFilters} />
            </Collapse>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardBody className="card-body">
          <Label
            onClick={() =>
              setOpenSection(
                openSection === "tableSettings" ? "" : "tableSettings",
              )
            }
            style={{
              marginBottom: "1rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormattedString id="table_settings" />
            <i
              className={`ri-arrow-${openSection === "tableSettings" ? "up" : "down"}-s-line`}
              style={{ marginLeft: "auto", fontSize: "18px" }}
            ></i>
          </Label>
          <Collapse isOpen={openSection === "tableSettings"}>
            <div className="mb-3">
              <Label for="pageSize">
                <FormattedString id="page_size_label" />
              </Label>
              <Input
                type="number"
                id="pageSize"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                min={1}
                max={99}
              />
            </div>
          </Collapse>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
