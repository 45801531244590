// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clear-button {
  border: solid 1px transparent;
  transition: all 0.3s ease;
  width: 100%;
  font-size: 15px;
  display: flex;
  color: var(--vz-danger);
  align-items: center;
  padding-left: 0;
  gap: 6px;
  background: none;
}
.clear-button__icon {
  padding: 6px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  border: solid var(--vz-danger) 1px;
  color: var(--vz-danger);
  align-items: center;
  border-radius: 100%;
  transition: all 0.3s ease;
}
.clear-button:hover .clear-button__icon {
  background: var(--vz-danger);
  color: white;
}
.clear-button--disabled {
  color: var(--vz-gray-600);
}
.clear-button--disabled .clear-button__icon {
  background: transparent;
  border: solid var(--vz-gray-600) 1px;
  color: var(--vz-gray-600);
}
.clear-button--disabled:hover .clear-button__icon {
  background: transparent;
  border: solid var(--vz-gray-600) 1px;
  color: var(--vz-gray-600);
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/ClearButton/ClearButton.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,yBAAA;EACA,WAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,QAAA;EACA,gBAAA;AACF;AACE;EACE,YAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,kCAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;AACJ;AAGI;EACE,4BAAA;EACA,YAAA;AADN;AAKE;EACE,yBAAA;AAHJ;AAKI;EACE,uBAAA;EACA,oCAAA;EACA,yBAAA;AAHN;AAMI;EACE,uBAAA;EACA,oCAAA;EACA,yBAAA;AAJN","sourcesContent":[".clear-button {\n  border: solid 1px transparent;\n  transition: all 0.3s ease;\n  width: 100%;\n  font-size: 15px;\n  display: flex;\n  color: var(--vz-danger);\n  align-items: center;\n  padding-left: 0;\n  gap: 6px;\n  background: none;\n\n  &__icon {\n    padding: 6px;\n    height: 24px;\n    width: 24px;\n    display: flex;\n    justify-content: center;\n    border: solid var(--vz-danger) 1px;\n    color: var(--vz-danger);\n    align-items: center;\n    border-radius: 100%;\n    transition: all 0.3s ease;\n  }\n\n  &:hover {\n    .clear-button__icon {\n      background: var(--vz-danger);\n      color: white;\n    }\n  }\n\n  &--disabled {\n    color: var(--vz-gray-600);\n\n    .clear-button__icon {\n      background: transparent;\n      border: solid var(--vz-gray-600) 1px;\n      color: var(--vz-gray-600);\n    }\n\n    &:hover .clear-button__icon {\n      background: transparent;\n      border: solid var(--vz-gray-600) 1px;\n      color: var(--vz-gray-600);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
