import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

// Crypto Redux States
import {
  GET_COORDINATOR_LIST_DATA,
  POST_COORDINATOR,
  EDIT_COORDINATOR,
  ARCHIVE_COORDINATOR,
  RESTORE_COORDINATOR,
} from "./actionType";
import { coordinatorsApiSuccess, coordinatorsApiError } from "./action";

//Include Both Helper File with needed methods
import {
  getCoordinatorList,
  postCoordinator,
  editCoordinator,
  archiveCoordinator,
  restoreCoordinator,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getCoordinatorListData({ payload: data }) {
  try {
    const response = yield call(getCoordinatorList, data);

    yield put(coordinatorsApiSuccess(GET_COORDINATOR_LIST_DATA, response));
  } catch (error) {
    yield put(coordinatorsApiError(GET_COORDINATOR_LIST_DATA, error));
  }
}

function* createCoordinator({ payload: coordinator }) {
  try {
    const response = yield call(
      postCoordinator,
      coordinator.data,
      coordinator.onSuccess,
    );
    yield put(coordinatorsApiSuccess(POST_COORDINATOR, response));
    toast_success("Coordinator added successfully");
  } catch (error) {
    yield put(coordinatorsApiError(POST_COORDINATOR, error));
    toast_error(error.data.message);
  }
}

function* editCoordinatorEntry({ payload: coordinator }) {
  try {
    const response = yield call(
      editCoordinator,
      coordinator.id,
      coordinator.data,
      coordinator.onSuccess,
    );
    yield put(coordinatorsApiSuccess(EDIT_COORDINATOR, response));
    toast_success("Coordinator edited successfully");
  } catch (error) {
    yield put(coordinatorsApiError(EDIT_COORDINATOR, error));
    toast_error(error.data.message);
  }
}

function* archiveCoordinatorEntry({ payload: coordinator }) {
  try {
    const response = yield call(
      archiveCoordinator,
      coordinator.id,
      coordinator.onSuccess,
    );
    yield put(coordinatorsApiSuccess(ARCHIVE_COORDINATOR, response));
    toast_success("Coordinator removed");
  } catch (error) {
    yield put(coordinatorsApiError(ARCHIVE_COORDINATOR, error));
    toast_error(error.data.message);
  }
}

function* restoreCoordinatorEntry({ payload: coordinator }) {
  try {
    const response = yield call(
      restoreCoordinator,
      coordinator.id,
      coordinator.onSuccess,
    );
    yield put(coordinatorsApiSuccess(RESTORE_COORDINATOR, response));
    toast_success("Coordinator restored");
  } catch (error) {
    yield put(coordinatorsApiError(RESTORE_COORDINATOR, error));
    toast_error(error.data.message);
  }
}

export function* watchGetCoordinatorListData() {
  yield takeEvery(GET_COORDINATOR_LIST_DATA, getCoordinatorListData);
}

export function* watchPostCoordinator() {
  yield takeEvery(POST_COORDINATOR, createCoordinator);
}
export function* watchEditCoordinator() {
  yield takeEvery(EDIT_COORDINATOR, editCoordinatorEntry);
}
export function* watchArchiveCoordinator() {
  yield takeEvery(ARCHIVE_COORDINATOR, archiveCoordinatorEntry);
}
export function* watchRestoreCoordinator() {
  yield takeEvery(RESTORE_COORDINATOR, restoreCoordinatorEntry);
}

function* coordinatorsSaga() {
  yield all([
    fork(watchGetCoordinatorListData),
    fork(watchPostCoordinator),
    fork(watchEditCoordinator),
    fork(watchArchiveCoordinator),
    fork(watchRestoreCoordinator),
  ]);
}

export default coordinatorsSaga;
