import React from "react";
import FormSwitch from "Components/Entity/FormSwitch";
import { getString } from "Components/Common/FormattedString";

const ShowOnlyAbandonedFilter = ({ onChange, filters }) => {
  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    onChange("onlyAbandoned", isChecked ? true : null);
  };

  return (
    <FormSwitch
      label={getString("show_only_abandoned")}
      name="onlyAbandoned"
      checked={filters.onlyAbandoned || false}
      onChange={handleSwitchChange}
      data-testid="switch-filter-showOnlyAbandoned"
      noPadding
    />
  );
};

export default ShowOnlyAbandonedFilter;
