import React, { useEffect } from "react";
import "./AddInternalCommentModal.scss";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { Input, Form, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";
import { addEntityNote } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import { getApplicationsListData } from "store/applications/action";

export const AddInternalCommentModal = ({
  toggle,
  visible,
  entityId,
  type,
  initialContent = "",
}) => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    content: Yup.string()
      .required(getString("required_field"))
      .max(2055, getString("max_length_5000")),
  });

  const formik = useFormik({
    initialValues: {
      content: initialContent,
    },
    validationSchema,
    onSubmit: (values) => {
      addEntityNote(entityId, type, values.content)
        .then(() => {
          toast_success(getString("comment_added"));
          toggle(false);
          dispatch(getApplicationsListData());
        })
        .catch((e) => toast_error(e.message));
    },
  });

  useEffect(() => {
    formik.setFieldValue("content", initialContent);
  }, [initialContent]);

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => toggle(false)}
      onSave={formik.handleSubmit}
      id="add-internal-comment-modal"
      title={getString("add_internal_comment")}
      showFooter={true}
    >
      <Form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <Label for="content">{getString("internal_comment_content")}</Label>
          <Input
            type="textarea"
            name="content"
            id="content"
            value={formik.values.content}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={formik.touched.content && !!formik.errors.content}
          />
          {formik.touched.content && formik.errors.content ? (
            <div className="invalid-feedback">{formik.errors.content}</div>
          ) : null}
        </FormGroup>
      </Form>
    </SimplestModal>
  );
};
