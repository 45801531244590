import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_PAYMENT_METHOD_LIST_DATA } from "./actionType";
import { paymentMethodsApiSuccess, paymentMethodsApiError } from "./action";

//Include Both Helper File with needed methods
import { getPaymentMethodList } from "../../helpers/API/core-service/cs_backend_helper";

function* getPaymentMethodListData({ payload: data }) {
  try {
    const response = yield call(getPaymentMethodList, data);

    yield put(paymentMethodsApiSuccess(GET_PAYMENT_METHOD_LIST_DATA, response));
  } catch (error) {
    yield put(paymentMethodsApiError(GET_PAYMENT_METHOD_LIST_DATA, error));
  }
}

export function* watchGetPaymentMethodListData() {
  yield takeEvery(GET_PAYMENT_METHOD_LIST_DATA, getPaymentMethodListData);
}

function* paymentMethodsSaga() {
  yield all([fork(watchGetPaymentMethodListData)]);
}

export default paymentMethodsSaga;
