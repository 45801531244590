import { useMemo, useState } from "react";
import "./FormPhoneField.scss";
import "react-intl-tel-input/dist/main.css";
import IntlTelInput from "react-intl-tel-input";
import classNames from "classnames";
import { dialCodeToCountryCode } from "./utils/dialCodeToCountryCode";
import { preferredCountries } from "./utils/preferredCountries";
import { FormFeedback, Label } from "reactstrap";

const FormPhoneField = ({
  label,
  name,
  phonePrefixName,
  value,
  onChange,
  error,
  phonePrefix,
  onPrefixChange,
  disabled = false,
}) => {
  const defaultCountryCode = useMemo(() => {
    const countryCode = Object.keys(dialCodeToCountryCode).find((key) => {
      return key.toString() === phonePrefix?.toString();
    });
    return countryCode ? dialCodeToCountryCode[countryCode] : "pl";
  }, [phonePrefix]);

  const handlePhoneNumberChange = (
    _isValid,
    _value,
    _countryData,
    _number,
    _id,
  ) => {
    const event = {
      target: {
        name: name,
        value: _value,
      },
    };
    onChange(event);
  };

  const handleSelectFlag = (_number, countryData) => {
    const countryChangeEvent = {
      target: {
        name: phonePrefixName,
        value: countryData.dialCode,
      },
    };
    onPrefixChange(countryChangeEvent);
  };

  const [inputKey, setInputKey] = useState(0);

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = (e.clipboardData || window.clipboardData).getData("text");
    const sanitizedPaste = paste.replace(/\D/g, "");

    const event = {
      target: {
        name: name,
        value: sanitizedPaste,
      },
    };
    onChange(event);

    setInputKey((prevKey) => prevKey + 1);
  };

  return (
    <div
      className={classNames([
        "form-phone-field mb-3",
        disabled && "form-phone-field--disabled",
        error && "form-phone-field--error",
      ])}
    >
      <Label htmlFor={name} className="form-label d-flex gap-1">
        {label}
      </Label>
      <IntlTelInput
        key={inputKey}
        containerClassName="intl-tel-input"
        inputClassName={`form-phone-field__input ${error ? "is-invalid" : ""}`}
        excludeCountries={["ca", "do"]}
        preferredCountries={preferredCountries}
        defaultValue={value}
        defaultCountry={defaultCountryCode}
        onPhoneNumberChange={handlePhoneNumberChange}
        onSelectFlag={handleSelectFlag}
        fieldName={name}
        separateDialCode={true}
        nationalMode={false}
        disabled={disabled}
        format={false}
        formatOnInit={false}
        telInputProps={{
          onKeyDown: (e) => {
            const allowedKeys = [
              "Backspace",
              "Delete",
              "ArrowLeft",
              "ArrowRight",
              "Tab",
              "Enter",
              "Escape",
              "Home",
              "End",
              "Shift",
              "Control",
              "Alt",
              "Meta",
              "CapsLock",
              "PageUp",
              "PageDown",
              "Insert",
            ];

            const ctrlCommands = ["a", "c", "v", "x"];

            if (
              !/[0-9]/.test(e.key) &&
              !allowedKeys.includes(e.key) &&
              !(e.ctrlKey && ctrlCommands.includes(e.key.toLowerCase()))
            ) {
              e.preventDefault();
            }
          },
          onPaste: handlePaste,
        }}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default FormPhoneField;
