import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_POINT_OF_SELL_LIST_DATA } from "./actionType";
import { pointOfSellApiSuccess, pointOfSellApiError } from "./action";

//Include Both Helper File with needed methods
import { getPointOfSellList } from "../../helpers/API/core-service/cs_backend_helper";

function* getPointOfSellListData({ payload: data }) {
  try {
    const response = yield call(getPointOfSellList, data);

    yield put(pointOfSellApiSuccess(GET_POINT_OF_SELL_LIST_DATA, response));
  } catch (error) {
    yield put(pointOfSellApiError(GET_POINT_OF_SELL_LIST_DATA, error));
  }
}

export function* watchGetPointOfSellListData() {
  yield takeEvery(GET_POINT_OF_SELL_LIST_DATA, getPointOfSellListData);
}

function* pointOfSellSaga() {
  yield all([fork(watchGetPointOfSellListData)]);
}

export default pointOfSellSaga;
