import React from "react";
import CountUp from "react-countup";

const SeasonComparison = ({ seasonComparison }) => {
  const { currentYear, previousYear } = seasonComparison;

  const metrics = [
    {
      label: "Total Applications",
      icon: "ri-space-ship-line",
      currentCounter: currentYear.applicationsCount,
      oldCounter: previousYear.applicationsCount,
      progressed:
        currentYear.applicationsCount > previousYear.applicationsCount,
    },
    {
      label: "Paid Applications",
      icon: "ri-exchange-dollar-line",
      currentCounter: currentYear.paidApplicationsCount,
      oldCounter: previousYear.paidApplicationsCount,
      progressed:
        currentYear.paidApplicationsCount > previousYear.paidApplicationsCount,
    },
    {
      label: "Canceled Applications",
      icon: "ri-pulse-line",
      currentCounter: currentYear.canceledApplicationsCount,
      oldCounter: previousYear.canceledApplicationsCount,
      progressed:
        currentYear.canceledApplicationsCount <
        previousYear.canceledApplicationsCount,
    },
    {
      label: "Abandoned Carts",
      icon: "ri-service-line",
      currentCounter: currentYear.abandonedApplications,
      oldCounter: previousYear.abandonedApplications,
      progressed:
        currentYear.abandonedApplications < previousYear.abandonedApplications,
    },
    {
      label: "Estimated Profit",
      icon: "ri-trophy-line",
      currentCounter: currentYear.estimatedProfit,
      oldCounter: 0,
      progressed: currentYear.estimatedProfit > previousYear.estimatedProfit,
      prefix: "$",
      separator: " ",
      suffix: " PLN",
    },
  ];

  return (
    <div className="col-xl-12">
      <div className="card crm-widget">
        <div className="card-body p-0">
          <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
            {metrics.map((item, index) => (
              <div className="col" key={index}>
                <div className="py-4 px-3">
                  <h5 className="text-muted text-uppercase fs-13">
                    {item.label}
                    {item.oldCounter !== 0 && (
                      <i
                        className={`ri-arrow-${
                          item.progressed ? "up" : "down"
                        }-circle-line text-${
                          item.progressed ? "success" : "danger"
                        } fs-18 float-end align-middle`}
                      ></i>
                    )}
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className={`${item.icon} display-6 text-muted`}></i>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h2 className="mb-0">
                        <span className="counter-value">
                          <CountUp
                            start={0}
                            suffix={item.suffix}
                            separator={item.separator}
                            end={item.currentCounter}
                            duration={2}
                          />
                          {item.oldCounter !== 0 && (
                            <span
                              className={`text-${
                                item.progressed ? "success" : "danger"
                              } fs-12`}
                            >
                              {" ("}
                              <CountUp
                                start={0}
                                suffix={item.suffix}
                                separator={item.separator}
                                end={item.oldCounter}
                                duration={2}
                              />
                              {")"}
                            </span>
                          )}
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeasonComparison;
