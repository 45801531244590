import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import "./JsonPreview.scss";

export const JsonPreview = ({ data }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Accordion open={open ? "1" : ""} toggle={toggle} className="json-preview">
      <AccordionItem>
        <AccordionHeader targetId="1" onClick={toggle}>
          {open ? "Hide JSON" : "Show JSON"}
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};
