// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preset-table-tabs .nav-link {
  display: flex;
  gap: 4px;
}
.preset-table-tabs .preset-tab {
  position: relative;
}
.preset-table-tabs .preset-tab .edit-icon:hover,
.preset-table-tabs .preset-tab .delete-icon:hover {
  color: #0056b3;
}
.preset-table-tabs .preset-tab .dropdown-toggle {
  cursor: pointer;
  color: #007bff;
  transition: color 0.3s ease;
}
.preset-table-tabs .preset-tab .dropdown-toggle:hover {
  color: #0056b3;
}
.preset-table-tabs .dropdown {
  z-index: 2000000 !important;
}

.preset-table-tabs-dd {
  padding: 0.5rem 0;
  margin-top: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}
.preset-table-tabs-dd .dropdown-header {
  padding: 0.35rem 1.2rem;
  display: block;
  margin-bottom: 0;
  font-size: 0.7109375rem;
  color: #878a99;
  white-space: nowrap;
}
.preset-table-tabs-dd .dropdown-item {
  padding: 0.35rem 1.2rem;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #212529;
  font-weight: 500;
}
.preset-table-tabs-dd .dropdown-item:hover {
  background-color: #eff2f7;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/PresetTable/components/PresetTableTabs.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,QAAA;AAAJ;AAEE;EACE,kBAAA;AAAJ;AAGE;;EAEE,cAAA;AADJ;AAIE;EACE,eAAA;EACA,cAAA;EACA,2BAAA;AAFJ;AAKE;EACE,cAAA;AAHJ;AAKE;EACE,2BAAA;AAHJ;;AAQA;EACE,iBAAA;EACA,eAAA;EACA,uEACE;AANJ;AASE;EACE,uBAAA;EACA,cAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;EACA,mBAAA;AAPJ;AASE;EACE,uBAAA;EACA,aAAA;EACA,QAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;AAPJ;AASI;EACE,yBAAA;AAPN","sourcesContent":[".preset-table-tabs {\n  .nav-link {\n    display: flex;\n    gap: 4px;\n  }\n  .preset-tab {\n    position: relative;\n  }\n\n  .preset-tab .edit-icon:hover,\n  .preset-tab .delete-icon:hover {\n    color: #0056b3;\n  }\n\n  .preset-tab .dropdown-toggle {\n    cursor: pointer;\n    color: #007bff;\n    transition: color 0.3s ease;\n  }\n\n  .preset-tab .dropdown-toggle:hover {\n    color: #0056b3;\n  }\n  .dropdown {\n    z-index: 2000000 !important;\n  }\n}\n\n// DropdownItem.scss\n.preset-table-tabs-dd {\n  padding: 0.5rem 0;\n  margin-top: 6px;\n  box-shadow:\n    0 4px 8px rgba(0, 0, 0, 0.1),\n    0 6px 20px rgba(0, 0, 0, 0.1);\n\n  .dropdown-header {\n    padding: 0.35rem 1.2rem;\n    display: block;\n    margin-bottom: 0;\n    font-size: 0.7109375rem;\n    color: #878a99;\n    white-space: nowrap;\n  }\n  .dropdown-item {\n    padding: 0.35rem 1.2rem;\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    color: #212529;\n    font-weight: 500;\n\n    &:hover {\n      background-color: #eff2f7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
