import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_BILLING_DATA_LIST,
  SET_CURRENT_PAGE,
} from "./actionType";

export const INIT_STATE = {
  billingData: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  count: 0,
  currentPage: 1,
  totalPages: 0,
  pageSize: 10,
};

const BillingData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BILLING_DATA_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BILLING_DATA_LIST:
          return {
            ...state,
            billingData: action.payload.data.data,
            count: action.payload.data.count,
            currentPage: action.payload.data.currentPage,
            totalPages: action.payload.data.totalPages,
            pageSize: action.payload.data.pageSize,
            loading: false,
            error: null,
          };

        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_BILLING_DATA_LIST:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            loading: false,
          };
        default:
          return state;
      }

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
};

export default BillingData;
