import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Card, CardBody, Form, FormGroup } from "reactstrap";
import { getString, getStringSync } from "Components/Common/FormattedString";
import { handleSelectChange } from "helpers/validation_helper";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { updateIpStatus } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import { ipGroupedStatusOptions } from "models/ipParticipants";
import { FormSelectGrouped } from "Components/Entity/FormSelectGrouped";

export const ChangeIpStatus = ({
  visible,
  setVisible,
  itemId,
  programPacketId,
  initialStatus,
}) => {
  const { refreshProgramData } = useContext(ProgramDetailsContext);
  const [loading, setLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      status: initialStatus || "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required(getStringSync("select_ip_status")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      updateIpStatus(programPacketId, itemId, values.status)
        .then(() => {
          toast_success(getString("ip_status_updated"));
          setVisible(false);
          refreshProgramData();
        })
        .catch((e) => toast_error(e.message))
        .finally(() => setLoading(false));
    },
  });

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("change_ip_status")}
      formId="change-ip-status-form"
      validationRule={true}
      buttonLabel={getString("save")}
      loading={loading}
    >
      <Card className="change-ip-status">
        <CardBody className="card-body">
          <Form
            id="change-ip-status-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormGroup>
              <FormSelectGrouped
                name="status"
                id="statusSelect"
                label={getString("select_ip_status")}
                isMulti={false}
                options={ipGroupedStatusOptions}
                value={validation.values.status}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "status")
                }
                error={validation.errors.status}
              />
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
