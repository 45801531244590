import { call, put, takeEvery, all, fork, select } from "redux-saga/effects";
import {
  FETCH_PROFILE,
  UPDATE_TABLE_PRESETS,
  SET_ACTIVE_PRESET,
} from "./actionTypes";
import {
  profileError,
  profileSuccess,
  updateTablePresetsSuccess,
  updateTablePresetsError,
  setTableActivePresetSuccess,
  setTableActivePresetError,
} from "./actions";
import {
  fetchProfile,
  updateTablePresets,
} from "../../../helpers/API/account-service/as_backend_helper";
import { isEmpty } from "lodash";
import { toast_error, toast_success } from "helpers/toast_helper";
import { logoutUser } from "../login/saga";
import { getString } from "Components/Common/FormattedString";

const getTablePresets = (state) => state.Profile.user.tablePresets;

function* fetchUserProfile() {
  try {
    const response = yield call(fetchProfile);

    if (!isEmpty(response)) {
      if (response.businessUnits.length === 0) {
        toast_error(getString("no_business_units"));
        yield call(logoutUser);
        return;
      }

      localStorage.setItem("userData", JSON.stringify(response));
      yield put(profileSuccess(FETCH_PROFILE, response));
    } else {
      yield put(profileError(FETCH_PROFILE, response));
    }
  } catch (error) {
    toast_error(error.message);
    yield call(logoutUser);
    yield put(profileError(FETCH_PROFILE, error));
  }
}

function* updateTablePresetsSaga({ payload: { userId, tablePresets } }) {
  try {
    const response = yield call(updateTablePresets, userId, tablePresets);

    yield put(updateTablePresetsSuccess(response.data));
    toast_success(getString("table_presets_updated"));
  } catch (error) {
    yield put(updateTablePresetsError(error));
    toast_error(error.message);
  }
}

function* setTableActivePresetSaga({
  payload: { userId, tableName, presetName },
}) {
  try {
    const tablePresets = yield select(getTablePresets);

    const updatedTablePresets = tablePresets.map((preset) =>
      preset.tableName === tableName
        ? {
            ...preset,
            activePreset: presetName,
          }
        : preset,
    );

    yield call(updateTablePresets, userId, updatedTablePresets);

    yield put(setTableActivePresetSuccess(updatedTablePresets));
  } catch (error) {
    yield put(setTableActivePresetError(error));
    toast_error(error.message);
  }
}

export function* watchFetchUserProfile() {
  yield takeEvery(FETCH_PROFILE, fetchUserProfile);
}

export function* watchUpdateTablePresets() {
  yield takeEvery(UPDATE_TABLE_PRESETS, updateTablePresetsSaga);
}

export function* watchSetTableActivePreset() {
  yield takeEvery(SET_ACTIVE_PRESET, setTableActivePresetSaga);
}

function* profileSaga() {
  yield all([
    fork(watchFetchUserProfile),
    fork(watchUpdateTablePresets),
    fork(watchSetTableActivePreset),
  ]);
}

export default profileSaga;
