import React, { useEffect, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getProgramListData, postProgram } from "../../store/programs/action";

import {
  handleInputChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import FormTextField from "../../Components/Entity/FormTextField";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { getString } from "Components/Common/FormattedString";
import * as Yup from "yup";
import FormNumberField from "Components/Entity/FormNumberField";
import { getBusinessUnitListData } from "store/actions";
import { FormSelect } from "Components/Entity/FormSelect";
import FormDateField from "Components/Entity/FormDateField";
import { format } from "date-fns";

const AddProgram = ({ visibility, toggle }) => {
  const dispatch = useDispatch();
  const { loading, formValidationErrors } = useSelector(
    (rootState) => rootState.Programs,
  );

  const { user, activeBusinessUnit } = useSelector(
    (rootState) => rootState.Profile,
  );

  // Form validation (local)
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      businessUnitId:
        activeBusinessUnit.id === "all" ? "" : activeBusinessUnit.id,
      name: "",
      endDate: "",
      startDate: "",
      healthAndSafetyCapacity: "",
      season: "",
    },
    validationSchema: Yup.object().shape({
      businessUnitId: Yup.string().required(
        getString("businessUnitIdRequired"),
      ),
      name: Yup.string()
        .min(5, getString("nameMinLength", { min: 5 }))
        .required(getString("nameRequired")),
      endDate: Yup.date().required(getString("endDateRequired")).nullable(),
      startDate: Yup.date().required(getString("startDateRequired")).nullable(),
      healthAndSafetyCapacity: Yup.number()
        .required(getString("healthAndSafetyCapacityRequired"))
        .positive(getString("healthAndSafetyCapacityPositive"))
        .integer(getString("healthAndSafetyCapacityInteger")),
      season: Yup.string()
        .min(2, getString("seasonMinLength", { min: 2 }))
        .required(getString("seasonRequired")),
    }),
    onSubmit: (values) => {
      const newValues = {
        ...values,
        businessUnitId:
          values.businessUnitId === "all" ? undefined : values.businessUnitId,
      };
      newValues.startDate = format(
        new Date(values.startDate),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
      );
      newValues.endDate = format(
        new Date(values.endDate),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
      );

      dispatch(
        postProgram(newValues, () => {
          dispatch(getProgramListData());
          toggle(false);
        }),
      );
    },
  });

  // Form validation (api)
  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors === null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  useEffect(() => {
    dispatch(getBusinessUnitListData());
  }, []);

  const businessUnitOptions = (user.businessUnits || []).map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("programs_add_new_program")}
        formId={"add-program"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("add")}
      >
        <Form
          id="add-program"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              {activeBusinessUnit.id === "all" && (
                <FormSelect
                  name="businessUnitId"
                  label={getString("business_unit")}
                  defaultValue={businessUnitOptions.find(
                    (b) => b.value === validation.values.businessUnitId,
                  )}
                  value={validation.values.businessUnitId}
                  onChange={(selectedOption) =>
                    handleSelectChange(
                      validation,
                      selectedOption,
                      "businessUnitId",
                    )
                  }
                  isMulti={false}
                  options={businessUnitOptions}
                  error={validation.errors["businessUnitId"]}
                />
              )}
              <FormTextField
                label={getString("name")}
                name="name"
                value={validation.values.name}
                onChange={(e) => handleInputChange(validation, e, "name")}
                error={validation.errors.name}
                touched={validation.touched.name}
              />
              <FormTextField
                label={getString("season")}
                name="season"
                value={validation.values.season}
                onChange={(e) => handleInputChange(validation, e, "season")}
                error={validation.errors.season}
                touched={validation.touched.season}
              />
              <FormDateField
                label={getString("startDate")}
                name="startDate"
                value={validation.values.startDate}
                onChange={(e) => handleInputChange(validation, e, "startDate")}
                error={validation.errors.startDate}
                touched={validation.touched.startDate}
              />
              <FormDateField
                label={getString("endDate")}
                name="endDate"
                value={validation.values.endDate}
                onChange={(e) => handleInputChange(validation, e, "endDate")}
                error={validation.errors.endDate}
                touched={validation.touched.endDate}
              />
              <FormNumberField
                label={getString("healthAndSafetyCapacity")}
                name="healthAndSafetyCapacity"
                value={validation.values.healthAndSafetyCapacity}
                onChange={(e) =>
                  handleInputChange(validation, e, "healthAndSafetyCapacity")
                }
                error={validation.errors.healthAndSafetyCapacity}
                touched={validation.touched.healthAndSafetyCapacity}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default AddProgram;
