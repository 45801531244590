import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Dropdown } from "react-overlays";
import ReactDOM from "react-dom";
import { AddPresetCanvas } from "./AddPresetCanvas";
import { EditPresetCanvas } from "./EditPresetCanvas";
import { DeleteTablePresetModal } from "./DeleteTablePresetModal";
import "./PresetTableTabs.scss";
import { usePresetTableContext } from "../PresetTableContext";
import { useDispatch, useSelector } from "react-redux";
import { updateTablePresets } from "store/actions";
import { FormattedString } from "Components/Common/FormattedString";

const PresetTableTabs = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Profile);
  const { activePreset, presets, tableName, toggleTab, handlePageChange } =
    usePresetTableContext();

  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [editingPreset, setEditingPreset] = useState(null);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [presetToDelete, setPresetToDelete] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleEditPreset = (preset) => {
    setEditingPreset(preset);
    setEditVisible(true);
    setOpenDropdownIndex(null);
  };

  const handleDeletePreset = (presetName) => {
    setPresetToDelete(presetName);
    setDeleteModalVisible(true);
    setOpenDropdownIndex(null);
  };

  const confirmDeletePreset = () => {
    const updatedTablePresets = user.tablePresets.map((preset) =>
      preset.tableName === tableName
        ? {
            ...preset,
            presets: preset.presets.filter((p) => p.name !== presetToDelete),
            activePreset: "default",
          }
        : preset,
    );

    if (!updatedTablePresets.some((preset) => preset.tableName === tableName)) {
      updatedTablePresets.push({
        tableName,
        presets: [],
        activePreset: "default",
      });
    }

    handlePageChange(1);
    dispatch(updateTablePresets(user.id, updatedTablePresets));
    setDeleteModalVisible(false);
  };

  const hasActiveFilters = (filters) =>
    filters && Object.keys(filters).length > 0;

  return (
    <div className="preset-table-tabs" data-testid="preset-table-tabs">
      <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
        <NavItem>
          <NavLink
            className={classnames(
              { active: activePreset === "default" },
              "fw-semibold",
            )}
            onClick={() => toggleTab("default")}
            href="#"
          >
            <i className="ri-file-list-2-line me-1 align-bottom"></i>{" "}
            <FormattedString id="default_preset" />
          </NavLink>
        </NavItem>
        {presets
          .filter((preset) => preset.name !== "default")
          .map((preset, index) => (
            <NavItem key={preset.name} className="preset-tab">
              <NavLink
                className={classnames(
                  { active: activePreset === preset.name },
                  "fw-semibold",
                )}
                onClick={() => toggleTab(preset.name)}
                href="#"
              >
                {hasActiveFilters(preset.filters) && (
                  <i className="ri-filter-3-line"></i>
                )}
                {preset.name}
                {hasActiveFilters(preset.filters) && (
                  <span style={{ fontWeight: "lighter", opacity: "0.8" }}>
                    ({Object.keys(preset.filters).length})
                  </span>
                )}
                {!preset.disableEdit && (
                  <Dropdown
                    drop="down"
                    show={openDropdownIndex === index}
                    onToggle={() => toggleDropdown(index)}
                  >
                    <Dropdown.Toggle id="example-toggle">
                      {(props) => (
                        <i
                          className="ri-more-2-fill"
                          {...props}
                          data-testid={`dropdown-toggle-${index}`}
                        />
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu alignEnd>
                      {(props, { show }) =>
                        ReactDOM.createPortal(
                          <div
                            {...props}
                            className={`${show ? "flex" : "hidden"} shadow-lg border-grey-200 bg-white z-10 rounded preset-table-tabs-dd`}
                          >
                            <h6 className="dropdown-header">
                              <FormattedString id="table_preset_options" />
                            </h6>
                            <button
                              type="button"
                              tabIndex="0"
                              role="menuitem"
                              className="d-flex align-items-center dropdown-item"
                              onClick={() => handleEditPreset(preset)}
                              data-testid="edit-preset-button"
                            >
                              <i className="ri-edit-line"></i>
                              <span>
                                <FormattedString id="edit" />
                              </span>
                            </button>
                            <button
                              type="button"
                              tabIndex="0"
                              role="menuitem"
                              className="d-flex align-items-center dropdown-item text-danger"
                              onClick={() => handleDeletePreset(preset.name)}
                              disabled={preset.disableRemove}
                              data-testid="delete-preset-button"
                            >
                              <i className="ri-delete-bin-line text-danger"></i>
                              <span>
                                <FormattedString id="delete" />
                              </span>
                            </button>
                          </div>,
                          document.body,
                        )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </NavLink>
            </NavItem>
          ))}
        <NavItem>
          <NavLink
            className="fw-semibold"
            onClick={() => setAddVisible(true)}
            href="#"
            data-testid="add-preset-button"
          >
            <i className="ri-add-line me-1 align-bottom"></i>{" "}
            <FormattedString id="add_preset" />
          </NavLink>
        </NavItem>
      </Nav>

      {addVisible && (
        <AddPresetCanvas visible={addVisible} setVisible={setAddVisible} />
      )}
      {editVisible && (
        <EditPresetCanvas
          visible={editVisible}
          setVisible={setEditVisible}
          presetToEdit={editingPreset}
        />
      )}
      {deleteModalVisible && (
        <DeleteTablePresetModal
          presetName={presetToDelete}
          visibility={deleteModalVisible}
          toggle={() => setDeleteModalVisible(false)}
          handleDelete={confirmDeletePreset}
        />
      )}
    </div>
  );
};

export default PresetTableTabs;
