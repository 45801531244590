import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import profileSaga from "./auth/profile/saga";

// in sidebar order
import dashboardCrmSaga from "./dashboardCRM/saga";
import hotelsSaga from "./hotels/saga";
import usersSaga from "./users/saga";
import meetingPointsSaga from "./meetingPoints/saga";
import coordinatorsSaga from "./coordinators/saga";
import programsSaga from "./programs/saga";
import attachmentsSaga from "./attachments/saga";
import paymentMethodsSaga from "./paymentMethods/saga";
import upsellsSaga from "./upsells/saga";
import applicationsSaga from "./applications/saga";
import pointOfSellSaga from "./pointOfSell/saga";
import programPacketTemplatesSaga from "./programPacketTemplates/saga";
import installmentPlansSaga from "./installmentPlans/saga";
import ipParticipantsSaga from "./ipParticipants/saga";
import customersSaga from "./customers/saga";
import ipApplicationsSaga from "./ipApplications/saga";
import billingDataSaga from "./billingData/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(programsSaga),
    fork(AuthSaga),
    fork(profileSaga),
    fork(dashboardCrmSaga),
    fork(hotelsSaga),
    fork(usersSaga),
    fork(meetingPointsSaga),
    fork(coordinatorsSaga),
    fork(attachmentsSaga),
    fork(paymentMethodsSaga),
    fork(upsellsSaga),
    fork(applicationsSaga),
    fork(pointOfSellSaga),
    fork(programPacketTemplatesSaga),
    fork(installmentPlansSaga),
    fork(ipParticipantsSaga),
    fork(customersSaga),
    fork(ipApplicationsSaga),
    fork(billingDataSaga),
  ]);
}
