// google auth
export const GOOGLE_GRANT_AUTH = "/crm/auth/google/grant";
export const GET_PROFILE = "/user/profile";

// users
export const GET_USER_LIST = "/user";
export const GET_USER = "/user/%s";
export const POST_USER = "/user";
export const EDIT_USER = "/user/%s";
export const DELETE_USER = "/user/%s";

// customers
export const UPDATE_CUSTOMER = "/crm/customer/%s";
export const UPDATE_CUSTOMER_PAYER_DETAILS =
  "/crm/customer/%s/payer-details/%s";
export const UPDATE_TABLE_PRESETS = "/user/%s/table-presets";
