import React from "react";
import { Input, Label, FormFeedback, Button } from "reactstrap";
import { FormattedString } from "Components/Common/FormattedString";

const FormDateRange = ({
  label,
  startName,
  endName,
  startValue,
  endValue,
  onChange,
  startError,
  endError,
  disabled,
  min,
  max,
  "data-testid": dataTestId,
}) => {
  const handleStartChange = (e) => onChange(e, startName);
  const handleEndChange = (e) => onChange(e, endName);

  const handleClearStart = () => onChange({ target: { value: "" } }, startName);
  const handleClearEnd = () => onChange({ target: { value: "" } }, endName);

  return (
    <div className="mb-3" data-testid={dataTestId}>
      {label && <Label className="form-label">{label}</Label>}
      <div className="d-flex gap-2">
        <div className="flex-grow-1" style={{ position: "relative" }}>
          <Label htmlFor={startName} className="form-label small text-muted">
            <FormattedString id={startName} />
          </Label>
          <Input
            type="date"
            className="form-control"
            id={startName}
            name={startName}
            onChange={handleStartChange}
            value={startValue || ""}
            invalid={!!startError}
            disabled={disabled}
            min={min}
            max={max}
            data-testid={`${dataTestId}-from`}
          />
          {startError && (
            <FormFeedback type="invalid">{startError}</FormFeedback>
          )}
          {startValue && (
            <Button
              type="button"
              color="link"
              onClick={handleClearStart}
              style={{
                position: "absolute",
                top: "35px",
                right: "36px",
                padding: "0px",
                fontSize: "15px",
                boxShadow: "none",
                color: "#828282",
                backgroundColor: "transparent",
              }}
            >
              <i className="ri-close-circle-line"></i>
            </Button>
          )}
        </div>
        <div className="flex-grow-1" style={{ position: "relative" }}>
          <Label htmlFor={endName} className="form-label small text-muted">
            <FormattedString id={endName} />
          </Label>
          <Input
            type="date"
            className="form-control"
            id={endName}
            name={endName}
            onChange={handleEndChange}
            value={endValue || ""}
            invalid={!!endError}
            disabled={disabled}
            min={min}
            max={max}
            data-testid={`${dataTestId}-to`}
          />
          {endError && <FormFeedback type="invalid">{endError}</FormFeedback>}
          {endValue && (
            <Button
              type="button"
              color="link"
              onClick={handleClearEnd}
              style={{
                position: "absolute",
                top: "35px",
                right: "36px",
                padding: "0px",
                fontSize: "15px",
                boxShadow: "none",
                color: "#828282",
                backgroundColor: "transparent",
              }}
            >
              <i className="ri-close-circle-line"></i>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormDateRange;
