function convertErrorToFormikErrors(error) {
  const errors = {};

  if (error.data && error.data.fields) {
    error.data.fields.forEach((field) => {
      errors[field.name] = field.message;
    });
  }

  return errors;
}

function parseErrorToFormikErrors(error, formik) {
  formik.setErrors(convertErrorToFormikErrors(error));
}

export { convertErrorToFormikErrors, parseErrorToFormikErrors };
