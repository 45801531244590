import React from "react";
import { Input, Label, FormFeedback } from "reactstrap";
import { FormattedString } from "Components/Common/FormattedString";

const FormRangeField = ({
  label,
  fromName,
  toName,
  fromValue,
  toValue,
  onChange,
  fromError,
  toError,
  disabled = false,
  min,
  max,
}) => {
  const handleFromChange = (e) => onChange(e, fromName);
  const handleToChange = (e) => onChange(e, toName);

  const isFromInvalid =
    !!fromError ||
    (fromValue !== "" && (fromValue < min || fromValue > max) && !disabled);
  const isToInvalid =
    !!toError ||
    (toValue !== "" && (toValue < min || toValue > max) && !disabled);

  return (
    <div className="mb-3">
      <Label className="form-label">{label}</Label>
      <div className="d-flex gap-2">
        <div className="flex-grow-1">
          <Label htmlFor={fromName} className="form-label">
            <FormattedString id="from_label" />
          </Label>
          <Input
            type="number"
            className="form-control"
            id={fromName}
            name={fromName}
            onChange={handleFromChange}
            value={
              fromValue !== null && fromValue !== undefined ? fromValue : ""
            }
            invalid={isFromInvalid}
            disabled={disabled}
            min={min}
            max={max}
          />
          {isFromInvalid && (
            <FormFeedback type="invalid">{fromError}</FormFeedback>
          )}
        </div>
        <div className="flex-grow-1">
          <Label htmlFor={toName} className="form-label">
            <FormattedString id="to_label" />
          </Label>
          <Input
            type="number"
            className="form-control"
            id={toName}
            name={toName}
            onChange={handleToChange}
            value={toValue !== null && toValue !== undefined ? toValue : ""}
            invalid={isToInvalid}
            disabled={disabled}
            min={min}
            max={max}
          />
          {isToInvalid && <FormFeedback type="invalid">{toError}</FormFeedback>}
        </div>
      </div>
    </div>
  );
};

export default FormRangeField;
