import React, { useEffect, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoordinatorListData,
  editCoordinator,
} from "../../store/coordinators/action";
import {
  coordinatorTypes,
  createCoordinatorSchema,
} from "../../models/coordinators";
import {
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { FormSelect } from "../../Components/Entity/FormSelect";
import { getUserListData } from "../../store/users/action";
import { getString } from "Components/Common/FormattedString";

const EditCoordinator = ({ coordinator, toggle, visibility }) => {
  const dispatch = useDispatch();
  const selectLayoutState = (state) => state.Coordinators;
  const selectLayoutProperties = createSelector(selectLayoutState, (state) => ({
    isCoordinatorEdited: state.isCoordinatorEdited,
    error: state.error,
    loading: state.loading,
    formValidationErrors: state.formValidationErrors,
  }));
  const { loading, formValidationErrors } = useSelector(selectLayoutProperties);

  // Form validation (local)
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      userId: coordinator?.userId || "",
      type: coordinator?.type || "",
    },
    validationSchema: createCoordinatorSchema,
    onSubmit: (values) => {
      dispatch(
        editCoordinator(coordinator.id, values, () => {
          dispatch(getCoordinatorListData());
          toggle(null, false);
        }),
      );
    },
  });

  // Form validation (api)
  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors === null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  // get users list
  useEffect(() => {
    dispatch(getUserListData());
  }, []);

  const { usersData } = useSelector((rootState) => rootState.Users);
  const userOptions = usersData
    .filter((item) => item?.status !== "archived")
    .map((u) => {
      return { value: u.id, label: `${u.firstName} ${u.lastName}` };
    });

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={
          getString("coordinators_edit") +
          " " +
          coordinator?.firstName +
          " " +
          coordinator?.lastName
        }
        formId={"edit-coordinator"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("save")}
      >
        <Form
          id="edit-coordinator"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              <FormSelect
                name="userId"
                label={getString("selected_user")}
                value={validation.values.userId}
                defaultValue={userOptions.find(
                  (r) => r.value === validation.values.userId,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "userId")
                }
                isMulti={false}
                options={userOptions}
                error={validation.errors["userId"]}
              />
              <FormSelect
                name="type"
                value={validation.values.type}
                defaultValue={coordinatorTypes.find(
                  (r) => r.value === validation.values.type,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "type")
                }
                isMulti={false}
                options={coordinatorTypes}
                error={validation.errors["type"]}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default EditCoordinator;
