import React, { useContext } from "react";
import "./ParticipantsTab.scss";
import { Card, CardBody, TabPane } from "reactstrap";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { FormattedString, getString } from "Components/Common/FormattedString";
import TableContainer from "Components/Common/TableContainerReactTable";

export const ParticipantsTab = () => {
  const { programData } = useContext(ProgramDetailsContext);
  const { participants } = programData;

  const columns = [
    {
      Header: <FormattedString id="firstName" />,
      accessor: "firstName",
    },
    {
      Header: <FormattedString id="lastName" />,
      accessor: "lastName",
    },
    {
      Header: <FormattedString id="phoneNumber" />,
      accessor: "phoneNumber",
      Cell: ({ row }) =>
        row.original.phoneNumber ? (
          <a
            href={`tel:+${row.original.phonePrefix}${row.original.phoneNumber}`}
          >
            +{row.original.phonePrefix} {row.original.phoneNumber}
          </a>
        ) : (
          "-"
        ),
    },
    {
      Header: <FormattedString id="email" />,
      accessor: "email",
      Cell: ({ value }) =>
        value ? <a href={`mailto:${value}`}>{value}</a> : "-",
    },
    {
      Header: <FormattedString id="dateOfBirth" />,
      accessor: "dateOfBirth",
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
    },
  ];

  return (
    <TabPane tabId="participants" className="participants-tab">
      {participants.map((packet) => (
        <Card key={packet.programPacketId}>
          <CardBody>
            <div className="d-flex align-items-center mb-4">
              <h5 className="card-title flex-grow-1 mb-0">
                {packet.programPacketName}
              </h5>
            </div>

            <TableContainer
              columns={columns}
              data={packet.data}
              isPagination={true}
              isGlobalFilter={true}
              isBordered={false}
              excludeUrlHandling
              customPageSize={10}
              className="custom-header-css table align-middle table-nowrap"
              tableClassName="table-centered align-middle table-nowrap mb-0"
              theadClassName="text-muted table-light"
              SearchPlaceholder={getString("participant_search_for")}
            />
          </CardBody>
        </Card>
      ))}
    </TabPane>
  );
};
