// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assign-ip-card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.assign-ip-card .program-header {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  margin: 0;
  margin-bottom: 4px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 4px;
}
.assign-ip-card .program-assignment {
  background-color: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 1rem;
}
.assign-ip-card .program-assignment > * {
  padding: 0.5rem 1rem;
}
.assign-ip-card .program-assignment .mb-3 {
  margin-bottom: 0 !important;
}
.assign-ip-card .remove-icon {
  cursor: pointer;
  color: #dc3545;
  transition: color 0.3s ease;
}
.assign-ip-card .remove-icon:hover {
  color: #a71d2a;
}
.assign-ip-card .card-body {
  padding: 1.5rem;
}
.assign-ip-card hr {
  border-top: 1px solid #e9ecef;
}
.assign-ip-card .sum {
  color: #007bff;
  font-weight: 500;
}
.assign-ip-card .form-select__option--is-focused .sum,
.assign-ip-card .form-select__option--is-focused .capacity {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/IpApplications/modals/AssignIpCanvas/AssignIpCanvas.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,wCAAA;AACF;AACE;EACE,eAAA;EACA,oBAAA;EACA,gBAAA;EACA,SAAA;EAEA,kBAAA;EACA,gCAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,yBAAA;EACA,kBAAA;EACA,mBAAA;AADJ;AAGI;EACE,oBAAA;AADN;AAII;EACE,2BAAA;AAFN;AAME;EACE,eAAA;EACA,cAAA;EACA,2BAAA;AAJJ;AAMI;EACE,cAAA;AAJN;AAQE;EACE,eAAA;AANJ;AASE;EACE,6BAAA;AAPJ;AAUE;EACE,cAAA;EACA,gBAAA;AARJ;AAWE;;EAEE,YAAA;AATJ","sourcesContent":[".assign-ip-card {\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n\n  .program-header {\n    font-size: 1rem;\n    padding: 0.5rem 1rem;\n    font-weight: 500;\n    margin: 0;\n\n    margin-bottom: 4px;\n    border-bottom: 1px solid #e9ecef;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #f8f9fa;\n    border-radius: 4px;\n  }\n\n  .program-assignment {\n    background-color: #f8f9fa;\n    border-radius: 4px;\n    margin-bottom: 1rem;\n\n    > * {\n      padding: 0.5rem 1rem;\n    }\n\n    .mb-3 {\n      margin-bottom: 0 !important;\n    }\n  }\n\n  .remove-icon {\n    cursor: pointer;\n    color: #dc3545;\n    transition: color 0.3s ease;\n\n    &:hover {\n      color: #a71d2a;\n    }\n  }\n\n  .card-body {\n    padding: 1.5rem;\n  }\n\n  hr {\n    border-top: 1px solid #e9ecef;\n  }\n\n  .sum {\n    color: #007bff;\n    font-weight: 500;\n  }\n\n  .form-select__option--is-focused .sum,\n  .form-select__option--is-focused .capacity {\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
