import React, { createContext, useContext, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  useRowSelect,
  useColumnOrder,
} from "react-table";
import { useSelector, useDispatch } from "react-redux";
import { setTableActivePreset, updateTablePresets } from "store/actions";
import {
  handleResetColumnSettings,
  handleToggleColumnVisibility,
} from "./components/utils";
import {
  defaultPresets,
  generateDefaultPreset,
} from "./components/defaultPresets";

export const PresetTableContext = createContext();

export const PresetTableProvider = ({
  children,
  columns,
  data,
  defaultColumn,
  tableName,
  count,
  currentPage,
  totalPages,
  pageSize,
  filterMapping,
}) => {
  const { user } = useSelector((state) => state.Profile);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const isInitialRender = useRef(true);

  if (user.tablePresets.length === 0) {
    user.tablePresets = defaultPresets;
  }

  const tablePresets =
    user.tablePresets.find((preset) => preset.tableName === tableName) ||
    generateDefaultPreset(tableName);

  const presets = tablePresets.presets || [];
  const activePreset = tablePresets.activePreset || "default";

  const setActivePreset = (presetName) => {
    dispatch(setTableActivePreset(user.id, tableName, presetName));
  };

  const [allLoading, setAllLoading] = useState(false);
  const [abandonedLoading, setAbandonedLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const pageNumber = searchParams.get("page") || "1";
  const globalFilter = searchParams.get("searchTerm") || "";

  const customSetGlobalFilter = (value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set("searchTerm", value);
    } else {
      newSearchParams.delete("searchTerm");
    }
    newSearchParams.set("page", "1");
    setSearchParams(newSearchParams);
  };

  const handlePageChange = (page) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("page", page.toString());
    setSearchParams(newSearchParams);
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const preset = presets.find((p) => p.name === activePreset);

  const saveSortBy = (tableName, presetName, sortBy) => {
    const allSettings = user.tablePresets.find(
      (preset) => preset.tableName === tableName,
    ) || {
      tableName,
      presets: [
        {
          name: "default",
          hiddenColumns: [],
          sortBy: [],
          order: [],
        },
      ],
      activePreset: "default",
    };

    const presetIndex = allSettings.presets.findIndex(
      (preset) => preset.name === presetName,
    );

    if (presetIndex !== -1) {
      allSettings.presets[presetIndex].sortBy = sortBy;
    } else if (presetName === "default") {
      allSettings.presets.push({
        name: "default",
        hiddenColumns: [],
        sortBy,
        order: [],
      });
    }

    const updatedTablePresets = user.tablePresets.map((preset) =>
      preset.tableName === tableName ? allSettings : preset,
    );

    if (!updatedTablePresets.some((preset) => preset.tableName === tableName)) {
      updatedTablePresets.push(allSettings);
    }

    dispatch(updateTablePresets(user.id, updatedTablePresets));
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setPageSize,
    setColumnOrder,
    allColumns,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualSortBy: true,
      disableMultiSort: true,
      useControlledState: (state) => ({
        ...state,
        sortBy: preset?.sortBy || [],
        hiddenColumns: preset?.hiddenColumns || [],
        columnOrder: preset?.order || [],
      }),
      stateReducer: (newState, action) => {
        if (action.type === "toggleSortBy") {
          saveSortBy(tableName, activePreset, newState.sortBy);
        }
        return newState;
      },
      initialState: {
        sortBy: preset?.sortBy || [],
        hiddenColumns: preset?.hiddenColumns || [],
        columnOrder: preset?.order || [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    useRowSelect,
    useColumnOrder,
  );

  const toggleTab = (tab) => {
    if (activePreset !== tab) {
      handlePageChange(1);
      setActivePreset(tab);
    }
  };

  return (
    <PresetTableContext.Provider
      value={{
        tableName,
        searchParams,
        setSearchParams,
        isInitialRender,
        allLoading,
        setAllLoading,
        abandonedLoading,
        setAbandonedLoading,
        modalOpen,
        setModalOpen,
        activePreset,
        setActivePreset,
        pageNumber,
        globalFilter,
        customSetGlobalFilter,
        handlePageChange,
        toggleModal,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setPageSize,
        allColumns,
        presets,
        toggleTab,
        handleResetColumnSettings,
        handleToggleColumnVisibility,
        setColumnOrder,
        count,
        currentPage,
        totalPages,
        pageSize,
        sortBy,
        preset,
        columns,
        filterMapping,
      }}
    >
      {children}
    </PresetTableContext.Provider>
  );
};

export const usePresetTableContext = () => useContext(PresetTableContext);
